<template>
  <!--begin::Groups-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.GROUPS") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.GROUPS_DESC") }} {{ totalGroups }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <!--begin: ADD-->
<!--      <div class="mb-7">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-lg-10 col-xl-10">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col-md-3 my-2 my-md-0">-->
<!--                <div class="input-icon">-->
<!--                  <input type="text" class="form-control" v-model="addGroup.name" placeholder="ADD NAME" />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-3 my-2 my-md-0">-->
<!--                <div class="input-icon">-->
<!--                  <input type="text" class="form-control" v-model="addGroup.description" placeholder="ADD DESCRIPTION" />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-3 my-2 my-md-0">-->
<!--                <button class="btn btn-secondary" @click="addNewGroup">ADD</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!--end: ADD-->

      <!--begin::Dummy-->
      <template v-if="!isGroupExists">
        <div class="justify-content-center row">
          <div class="col-auto d-flex">
            <GroupImage/>
          </div>
        </div>
        <div class="justify-content-center row">
          <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.GROUPS_NOT_YET_TITLE") }}
            <span class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.GROUPS_NOT_YET_DESC") }}</span>
          </h5>
        </div>
      </template>
      <!--end::Dummy-->
      <template v-if="isGroupExists">
        <!--begin::Search-->
        <div class="row align-items-center mb-7">
          <div class="col-lg-10 col-xl-10">
            <div class="row align-items-center">
              <div class="col-md-4 my-2 my-md-0">
                <div class="input-icon">
                  <input type="text" class="form-control" v-model="filter_name" :placeholder="$t('SELECT.SEARCH')" />
                  <span><i class="flaticon-search text-muted"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Search-->
        <!--begin::Table-->
        <div class="table-responsive">
          <b-table
              id="groups-table"
              class="table-vertical-center"
              show-empty
              :items="groupsList"
              :fields="fields"
              :per-page="0"
              :current-page="groupsListPagination.page">
            <template #empty="scope">
              <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
            </template>
            <template #head(check)="data">
              <label class="checkbox checkbox-single mr-2">
                <input
                    type="checkbox"
                />
                <span></span>
              </label>
            </template>
            <template #head()="data">
              <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
            </template>
            <template #cell(name)="data">
              <router-link :disabled="!isCan"
                           :event="isCan ? 'click' : ''"
                           :style="isCan ? 'text-decoration: underline': ''"
                  :to="{ name: 'group', params: { id: data.item.id } }">
                <p class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mt-2 mb-0">{{ data.item.name }}</p>
              </router-link>
            </template>
            <template #cell(members)="data">
              {{ data.item.membersAmount }}
            </template>
            <template #cell(inReports)="data">
              <template v-if="data.item.visibleInReports"><Check/></template>
            </template>
          </b-table>
        </div>
        <!--end::Table-->
        <!--begin: Pagination-->
        <Pagination
            name="groups-table"
            :pagination="groupsListPagination"
            @changePageLimit="changePageLimit">
        </Pagination>
        <!--end: Pagination-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Groups-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_GROUPS_LIST } from "@/core/services/store/management.module";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";

import Check from "@/view/layout/svg/Check";
import GroupImage from "@/view/layout/svg/GroupImage";
import Pagination from "@/view/layout/pagination/Pagination";

export default {
  name: "Groups",
  components: {
    Check,
    GroupImage,
    Pagination
  },
  mounted() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    this.$store.dispatch(GET_GROUPS_LIST).then(() => {
      this.totalGroups = this.groupsListPagination.total;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.GROUPS") }]);
      this.isLoading = true;
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      localStorage.setItem("pageNumber", JSON.stringify({ page: 1, limit: 20 }));
    });
  },
  data() {
    return {
      // addGroup: {
      //   name: null,
      //   description: null
      // },
      totalGroups: 0,
      isLoading: false,
      isGroupExists: true,
      filter_name: '',
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.GROUPS_TABLE_TITLE") },
        { key: 'members', label: this.$t("MANAGEMENT.MEMBER_COUNT_TABLE_TITLE"), thClass: 'text-center', tdClass: 'text-center' },
        { key: 'inReports', label: this.$t("MANAGEMENT.IN_REPORTS_TABLE_TITLE"), thClass: 'text-center', tdClass: 'text-center' }
      ]
    }
  },
  watch: {
    filter_name(val) {
      this.$store.dispatch(GET_GROUPS_LIST, { name: val });
    }
  },
  computed: {
    ...mapGetters([
      "groupsList",
      "groupsListPagination"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
    foundGroups() {
      if (this.filter_name === "" && this.groupsList.length === 0) {
        this.isGroupExists = false;
      }
      return this.groupsList;
    }
  },
  methods: {
    // addNewGroup() {
    //   console.log(this.addGroup);
    //   this.$store.dispatch(POST_NEW_GROUP, this.addGroup);
    // },
    changePageLimit(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_GROUPS_LIST, val);
    }
  }
}
</script>

<style scoped>

</style>
