<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="446.627" height="200" viewBox="0 0 446.627 200">
    <defs>
      <clipPath id="clip-path">
        <path id="Контур_1172" data-name="Контур 1172" d="M18.172-1112.761s-43.641-94.728,7.708-138.364c41.633-35.379,82.637-23.694,117.22-12.01,30.407,10.273,55.848,20.547,72.391-1.167,58.487-76.772,249.827-57.069,191.285,38.4-30.715,50.086,72.046,42.6,30.153,114.388l-418.758-1.243" transform="translate(-0.873 1311.518)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Контур_1171" data-name="Контур 1171" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Контур_1175" data-name="Контур 1175" d="M1436.68-281.7h-43.516a2.994,2.994,0,0,1-2.994-2.992v-1.946h46.51v4.939" transform="translate(-1390.17 286.64)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Контур_1178" data-name="Контур 1178" d="M1529.625-218.541h-2.806l-.788-35.719h4.289l-.694,35.719" transform="translate(-1526.03 254.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Контур_1181" data-name="Контур 1181" d="M1477.9-40.748h-27.3v-2.466l13.913-1.255,13.385,1.255v2.466" transform="translate(-1450.6 44.47)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-9">
        <path id="Контур_1184" data-name="Контур 1184" d="M1433.21-35.085a2.245,2.245,0,0,1,2.245-2.245,2.246,2.246,0,0,1,2.247,2.245,2.246,2.246,0,0,1-2.247,2.245,2.245,2.245,0,0,1-2.245-2.245" transform="translate(-1433.21 37.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-11">
        <path id="Контур_1187" data-name="Контур 1187" d="M1612.2-35.085a2.245,2.245,0,0,1,2.245-2.245,2.245,2.245,0,0,1,2.245,2.245,2.245,2.245,0,0,1-2.245,2.245,2.245,2.245,0,0,1-2.245-2.245" transform="translate(-1612.2 37.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Контур_1190" data-name="Контур 1190" d="M1485.71-251.917h-18.87a2.05,2.05,0,0,1-2.05-2.05v-.293h22.97v.293a2.05,2.05,0,0,1-2.05,2.05" transform="translate(-1464.79 254.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <path id="Контур_1193" data-name="Контур 1193" d="M1394.068-510.9a3.9,3.9,0,0,1-3.9-3.9V-545.93c0-6.163,4.031-11.16,9-11.16h20.352c4.971,0,9,5,9,11.16V-510.9h-34.456" transform="translate(-1390.17 557.09)" fill="#43435c" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-16">
        <path id="Контур_1192" data-name="Контур 1192" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#43435c"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Контур_1196" data-name="Контур 1196" d="M1406.487-510.9a2.137,2.137,0,0,1-2.137-2.137V-545.93c0-6.163,4.03-11.16,9-11.16H1433.7c4.971,0,9,5,9,11.16V-510.9h-36.218" transform="translate(-1404.35 557.09)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-18">
        <path id="Контур_1195" data-name="Контур 1195" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#36364b"/>
      </clipPath>
      <clipPath id="clip-path-19">
        <path id="Контур_1199" data-name="Контур 1199" d="M1437.248-281.7h-49.789a2.47,2.47,0,0,1-2.469-2.469,2.469,2.469,0,0,1,2.469-2.469h49.789a2.469,2.469,0,0,1,2.469,2.469,2.47,2.47,0,0,1-2.469,2.469" transform="translate(-1384.99 286.64)" fill="#38384e" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-20">
        <path id="Контур_1198" data-name="Контур 1198" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#38384e"/>
      </clipPath>
      <clipPath id="clip-path-21">
        <path id="Контур_1202" data-name="Контур 1202" d="M1623.929-1056.733h-18.864a1.58,1.58,0,0,1-1.576-1.575v-18.208a1.58,1.58,0,0,1,1.576-1.575h18.864a1.58,1.58,0,0,1,1.576,1.575v18.208a1.58,1.58,0,0,1-1.576,1.575" transform="translate(-1603.49 1078.09)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Контур_1205" data-name="Контур 1205" d="M1628.417-1052.518h-17.229a1.442,1.442,0,0,1-1.438-1.438v-16.63a1.442,1.442,0,0,1,1.438-1.438h17.229a1.442,1.442,0,0,1,1.438,1.438v16.63a1.442,1.442,0,0,1-1.438,1.438" transform="translate(-1609.75 1072.024)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <path id="Контур_1208" data-name="Контур 1208" d="M1672.67-1061.778a.456.456,0,0,1,.456-.456.456.456,0,0,1,.456.456.456.456,0,0,1-.456.456.456.456,0,0,1-.456-.456" transform="translate(-1672.67 1062.234)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Контур_1211" data-name="Контур 1211" d="M1696.472-1056.322a.457.457,0,0,1,.621-.177.457.457,0,0,1,.177.62.455.455,0,0,1-.619.177.456.456,0,0,1-.178-.62" transform="translate(-1696.415 1056.556)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-29">
        <path id="Контур_1214" data-name="Контур 1214" d="M1716.3-1039a.457.457,0,0,1,.631.136.458.458,0,0,1-.136.63.457.457,0,0,1-.631-.136.458.458,0,0,1,.136-.63" transform="translate(-1716.096 1039.076)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-31">
        <path id="Контур_1217" data-name="Контур 1217" d="M1725.166-1013.183a.456.456,0,0,1,.456.456.456.456,0,0,1-.456.456.456.456,0,0,1-.456-.456.456.456,0,0,1,.456-.456" transform="translate(-1724.71 1013.183)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Контур_1220" data-name="Контур 1220" d="M1718.15-984.43a.455.455,0,0,1,0,.645.454.454,0,0,1-.644,0,.455.455,0,0,1,0-.645.455.455,0,0,1,.644,0" transform="translate(-1717.373 984.563)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <path id="Контур_1223" data-name="Контур 1223" d="M1699.371-965.606a.455.455,0,0,1,0,.645.456.456,0,0,1-.645,0,.455.455,0,0,1,0-.645.456.456,0,0,1,.645,0" transform="translate(-1698.593 965.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Контур_1226" data-name="Контур 1226" d="M1672.67-957.7a.456.456,0,0,1,.456-.456.456.456,0,0,1,.456.456.456.456,0,0,1-.456.456.456.456,0,0,1-.456-.456" transform="translate(-1672.67 958.152)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-39">
        <path id="Контур_1229" data-name="Контур 1229" d="M1649.679-1056.322a.457.457,0,0,0-.621-.177.457.457,0,0,0-.177.62.457.457,0,0,0,.621.177.457.457,0,0,0,.177-.62" transform="translate(-1648.824 1056.556)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-41">
        <path id="Контур_1232" data-name="Контур 1232" d="M1629.852-1039a.455.455,0,0,0-.63.136.455.455,0,0,0,.136.63.455.455,0,0,0,.63-.136.455.455,0,0,0-.136-.63" transform="translate(-1629.149 1039.076)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Контур_1235" data-name="Контур 1235" d="M1620.986-1013.183a.456.456,0,0,0-.456.456.456.456,0,0,0,.456.456.455.455,0,0,0,.456-.456.455.455,0,0,0-.456-.456" transform="translate(-1620.53 1013.183)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <path id="Контур_1238" data-name="Контур 1238" d="M1628.006-984.43a.455.455,0,0,0,0,.645.456.456,0,0,0,.645,0,.455.455,0,0,0,0-.645.456.456,0,0,0-.645,0" transform="translate(-1627.872 984.563)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Контур_1241" data-name="Контур 1241" d="M1646.786-965.606a.455.455,0,0,0,0,.645.454.454,0,0,0,.644,0,.455.455,0,0,0,0-.645.454.454,0,0,0-.644,0" transform="translate(-1646.653 965.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-49">
        <path id="Контур_1244" data-name="Контур 1244" d="M1674.85-1012.727a.456.456,0,0,1,.456-.456.455.455,0,0,1,.456.456.455.455,0,0,1-.456.456.456.456,0,0,1-.456-.456" transform="translate(-1674.85 1013.183)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-51">
        <path id="Контур_1247" data-name="Контур 1247" d="M1677.44-1041.846h.124v-5.7h-.124Z" transform="translate(-1677.44 1047.543)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Контур_1250" data-name="Контур 1250" d="M1676.94-1024.089l-.23-.294,3.02-2.371.23.294-3.02,2.371" transform="translate(-1676.71 1026.753)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <path id="Контур_1253" data-name="Контур 1253" d="M1805.43-57.9h-18.537c-.891-1.087-1.391-6.2-1.255-7.515.027-.264.36-1.492.36-1.492s6.845-2.887,7.714.99c.026.122.058.241.093.357,1.1,3.627,6.009,5.274,8.846,5.733a3.217,3.217,0,0,1,2.779,1.926" transform="translate(-1785.615 67.936)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Контур_1256" data-name="Контур 1256" d="M1518.081-381.46c.67,0,25.375,3.455,30.516,9.234,2.568,2.886,9.284,40.711,9.284,40.711s-3.122,2.215-9.985.139c0,0-9.057-24.475-8.686-31.393.1-1.913-21.963-2.713-25.868-3.63s-8.753-3.169-6.65-6.927,11.389-8.132,11.389-8.132" transform="translate(-1506.183 381.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-59">
        <path id="Контур_1259" data-name="Контур 1259" d="M1728.278-54.774h-18.536c-.892-1.087-.564-5.1-.429-6.412.027-.264.047-.418.047-.418s6.33-5.064,7.2-1.187c.026.122.058.241.093.357,1.1,3.627,6.009,5.274,8.846,5.733a3.213,3.213,0,0,1,2.779,1.926" transform="translate(-1709.141 64.252)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-61">
        <path id="Контур_1262" data-name="Контур 1262" d="M1476-385.359c.67,0,30.176,1.466,35.318,7.829,2.568,3.18,2.95,39.9,2.95,39.9s-3.177,3.94-10.039,1.652c0,0-6.307-19.48-5.843-32.746.073-2.108-18.368-2.35-23.432-2.753-6.424-.511-8.047-11.293-5.945-15.432s6.992,1.553,6.992,1.553" transform="translate(-1468.138 388.421)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Контур_1265" data-name="Контур 1265" d="M1536.144-684.984l-.371.075a3.064,3.064,0,0,1-3.6-2.391l-.391-1.934a3.065,3.065,0,0,1,2.39-3.6l.371-.075a3.065,3.065,0,0,1,3.6,2.391l.392,1.935a3.066,3.066,0,0,1-2.392,3.6" transform="translate(-1531.721 692.971)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <path id="Контур_1268" data-name="Контур 1268" d="M1473.733-578.17c-3.7,5.909-26.8,7.689-26.8,7.689-.391-.944-2.146-16.175-2.445-17.226a51.061,51.061,0,0,1-1.548-8.361c0-.037-.008-.073-.012-.11a97.225,97.225,0,0,1-.462-12.5c.041-1.646.105-2.658.11-2.709l.169-.07c1.4-.586,2.738-1.092,4.024-1.53l.011,0c1.08-.369,2.12-.686,3.121-.961a45.441,45.441,0,0,1,4.908-1.071,35.849,35.849,0,0,1,4.846-.447c.064,0,.128,0,.194,0,.381-.006.752,0,1.109,0a21.133,21.133,0,0,1,7.225,1.274c.168.067.313.13.435.186.015.006.032.012.047.02.137.061.244.113.319.149l.029.017c.035.018.07.038.1.058l1.9,19.977,2.721,15.617" transform="translate(-1442.429 615.475)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Контур_1271" data-name="Контур 1271" d="M1610.932-584.9c-2.642,1.226-18.351,2.256-19.86,1.821-.773-.224-4.013-3.076-7.271-6.586l0,0c-3.083-3.32-6.192-7.226-7.24-10.039a17.2,17.2,0,0,1-.3-10.907s4.008-.017,5.643.969,10.977,18.2,12.226,18.65,16.048,1.3,17.743,2.106c0,0,1.7,2.761-.935,3.987" transform="translate(-1575.515 610.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-69">
        <path id="Контур_1274" data-name="Контур 1274" d="M1501.194-642.023l0,.973s.268,3.043-3.476,3.493-6.586-1.176-6.488-3.525a27.642,27.642,0,0,0,.726-4.571,10.662,10.662,0,0,0-.136-1.821l7.258-.156,2.138.345-.02,5.262" transform="translate(-1491.224 647.63)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-71">
        <path id="Контур_1277" data-name="Контур 1277" d="M1504.507-646.421l-.02,5.262a6.134,6.134,0,0,1-2.616.583c-3.336,0-6.656-5.364-6.761-6.034l9.4.189" transform="translate(-1495.11 646.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Контур_1280" data-name="Контур 1280" d="M1495.184-713.835s-9.293,1.891-10.382-3.914-3.023-9.472,2.864-10.926,7.46.545,8.3,2.394,1.612,11.8-.778,12.446" transform="translate(-1483.605 729.175)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <path id="Контур_1283" data-name="Контур 1283" d="M1483.5-749.368a19.486,19.486,0,0,1-5.652-1.52c-2.483-1.075-5.613.118-6.629.655a1.53,1.53,0,0,1,.247-.612,1.36,1.36,0,0,0-.522.777c-.047.035-.069.059-.056.067a1.749,1.749,0,0,0-.85.056s.233-.034.316.116a.152.152,0,0,0,.038.041c-3.87,1.877-4.217,8-2.97,9.409a31.02,31.02,0,0,1,3.305,5.534,3.288,3.288,0,0,0,.38-2.405c-.275-1.5,1.019-1.5,2.654-2.184s.268-2.589,1.7-3.75,6.676-.487,6.606-1.373c1.946-.712,1.437-4.611,1.435-4.81" transform="translate(-1466.782 751.322)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Контур_1286" data-name="Контур 1286" d="M1448.814-475.166c-.444-3.008-1.505-6.827-2-10.076-.413-2.7-.81-7.051-1.12-9.888,0-.037-.008-.073-.012-.11.02.009.053.064.075.037,1.026-1.246,3.828-2.61,7.22,2.356.677.99,3.448,7.072,3.349,8.32-.452,5.683-7.51,9.36-7.51,9.36" transform="translate(-1445.68 496.369)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-79">
        <path id="Контур_1289" data-name="Контур 1289" d="M1480.586-432.141a2.813,2.813,0,0,1,.358-1.716s.435-.281,1.011-.61a5.906,5.906,0,0,1,2.572-.979c.1.018.224.046.378.082,1.264.3,4.213,1.213,4.66,1.745.366.438.866.994,1.211,1.478h-10.19" transform="translate(-1480.56 435.456)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-81">
        <path id="Контур_1292" data-name="Контур 1292" d="M1355.235-567.978H1330.4a1.506,1.506,0,0,1,.018-.834c.741-1.871,2.187-4.952,3.853-8.257,1.225-2.431,2.567-4.986,3.834-7.269a51.253,51.253,0,0,1,4.319-6.911,11.182,11.182,0,0,1,2.672-2.166,18.9,18.9,0,0,1,2.492-1.246c.057-.024.113-.047.169-.07a22.065,22.065,0,0,1,3.1-1.008s.018.785.02,1.992c0,3.5-.13,10.556-1.246,12.276a23.319,23.319,0,0,1-1.617,2.054l-.063.073c-.392.459-.828.955-1.284,1.464-2.591,2.9-5.875,6.313-6.275,6.729l-.041.041h.047l6.964.1,2.137.029,4.429.061a1.677,1.677,0,0,1,.726.618,4.48,4.48,0,0,1,.58,2.326" transform="translate(-1330.35 595.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Контур_1295" data-name="Контур 1295" d="M1656.98-471.265c-2.642,1.226-18.351,2.256-19.86,1.821-.773-.224-4.013-3.076-7.271-6.586.16.021,1.65-2.462,8.445,3.481,0,0,1.778.793,6.621.349s11.061-.605,12.064.935" transform="translate(-1629.85 476.527)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <path id="Контур_1298" data-name="Контур 1298" d="M1484.578-684.984l-.371.075a3.066,3.066,0,0,1-3.6-2.391l-.39-1.934a3.066,3.066,0,0,1,2.391-3.6l.369-.075a3.066,3.066,0,0,1,3.6,2.391l.39,1.935a3.066,3.066,0,0,1-2.39,3.6" transform="translate(-1480.154 692.971)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Контур_1301" data-name="Контур 1301" d="M1488.743-741.4a13.684,13.684,0,0,1,.378-6.915,4.6,4.6,0,0,1,2.222-2.709,2.379,2.379,0,0,1,1.146-.3l.975.016c-3.211,1.36-3.956,8.635-3.706,9.411a.247.247,0,0,0-.024-.054l-.99.548" transform="translate(-1488.469 751.322)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-89">
        <path id="Контур_1304" data-name="Контур 1304" d="M1506.746-662.208a9.951,9.951,0,0,1-8.1-4.31l.482-.3-.241.148.241-.148a9.37,9.37,0,0,0,7.614,4.039h.1l.006.566-.108,0" transform="translate(-1498.65 666.813)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-91">
        <path id="Контур_1307" data-name="Контур 1307" d="M1491.205-675.133l-.185.038a1.53,1.53,0,0,1-1.8-1.191l-.194-.965a1.526,1.526,0,0,1,1.191-1.795l.185-.037a1.527,1.527,0,0,1,1.794,1.191l.2.964a1.528,1.528,0,0,1-1.191,1.794" transform="translate(-1489.001 679.112)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Контур_1310" data-name="Контур 1310" d="M1550.681-640.627h-.509a.473.473,0,0,1-.471-.471.473.473,0,0,1,.471-.471h.509a.473.473,0,0,1,.471.471.473.473,0,0,1-.471.471" transform="translate(-1549.7 641.57)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <path id="Контур_1313" data-name="Контур 1313" d="M2690.776-190.861s-3.362-3.921-.207-6.41a8.929,8.929,0,0,1,4.18-1.281s-2.66,4.461-1.8,4.905,2.606-5.186,2.606-5.186,5.242-.508,6.927.3c0,0-4.479,3.667-3.537,3.886s5.783-3.381,5.783-3.381,4.879.83,4.618,3.807-1.94,3.894-2.68,3.815-5.061-1.728-5.155-1.123,2.507,2.584,4.33,2.675c0,0-3.439,3.793-5.726,2.7s-2.48-2.829-3.906-3.481-2.64-.648-2.053.015,3.168,1.309,3.982,2.75,1.547,2.413-.819,2.057-6.209-2.086-6.378-4.068l-.168-1.981" transform="translate(-2689.219 198.991)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Контур_1316" data-name="Контур 1316" d="M2699.216-167.318s-13.251-.511-16.177,3.279a9.6,9.6,0,0,1-2.639,3.7l-.311.845s2.3-2.421,3.117-2.567c0,0-1.284-4.695,16.009-5.26" transform="translate(-2680.09 167.341)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-99">
        <path id="Контур_1319" data-name="Контур 1319" d="M2665.231-123.263a8.317,8.317,0,0,0-3.041,5.861l.516.046s.909-3.886,2.55-5.405-.024-.5-.024-.5" transform="translate(-2662.19 123.596)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-101">
        <path id="Контур_1322" data-name="Контур 1322" d="M2513.439-254.935s5.346-2.994,2.851-7.056c0,0-1.995-2.5-4.133-3.136,0,0,1.141,6.058,0,6.2s-.784-6.842-.784-6.842-5.631-2.709-7.841-2.5c0,0,3.493,5.916,2.354,5.774s-5.061-6.13-5.061-6.13-5.774-1.069-6.7,2.352.571,5.132,1.425,5.346,6.345.142,6.2.854-3.848,1.855-5.917,1.213c0,0,2.282,5.631,5.276,5.346s3.92-2.138,5.773-2.282,3.207.357,2.28.856-4.062.163-5.559,1.437-2.709,2.056.072,2.626,7.769.214,8.767-1.925l1-2.138" transform="translate(-2493.831 268.777)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Контур_1325" data-name="Контур 1325" d="M2513.04-245.31s14.968,4.847,16.678,10.263a11.367,11.367,0,0,0,1.426,5.2v1.069s-1.568-3.636-2.424-4.135c0,0,3.349-4.7-15.681-12.4" transform="translate(-2513.04 245.31)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-105">
        <path id="Контур_1328" data-name="Контур 1328" d="M2627.763-147.6a9.87,9.87,0,0,1,.991,7.771l-.593-.159s.576-4.7-.631-7.063.233-.549.233-.549" transform="translate(-2627.047 148.264)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Контур_1331" data-name="Контур 1331" d="M2652.41-306.48s-7.046-3.946-3.758-9.3c0,0,2.631-3.288,5.449-4.135,0,0-1.5,7.986,0,8.175s1.034-9.02,1.034-9.02,7.422-3.57,10.335-3.288c0,0-4.6,7.8-3.1,7.611s6.671-8.081,6.671-8.081,7.609-1.409,8.831,3.1-.75,6.764-1.879,7.046-8.361.188-8.174,1.127,5.073,2.443,7.8,1.6c0,0-3.006,7.423-6.953,7.046s-5.167-2.819-7.609-3.006-4.228.47-3.008,1.127,5.356.217,7.329,1.893,3.571,2.712-.093,3.464-10.242.281-11.557-2.538l-1.316-2.819" transform="translate(-2647.778 324.727)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-109">
        <path id="Контур_1334" data-name="Контур 1334" d="M2689.7-293.79s-19.73,6.389-21.986,13.529a14.979,14.979,0,0,1-1.879,6.859v1.409s2.067-4.791,3.2-5.45c0,0-4.417-6.2,20.67-16.347" transform="translate(-2665.83 293.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-111">
        <path id="Контур_1337" data-name="Контур 1337" d="M2655.594-164.992a13.01,13.01,0,0,0-1.307,10.245l.782-.209s-.76-6.2.831-9.311-.306-.724-.306-.724" transform="translate(-2653.752 165.869)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Контур_1340" data-name="Контур 1340" d="M2599.895-89.675a13.8,13.8,0,0,0,13.8-13.8H2586.09a13.8,13.8,0,0,0,13.8,13.8" transform="translate(-2586.09 103.48)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <path id="Контур_1343" data-name="Контур 1343" d="M2612.838-17.836H2584.85a1.7,1.7,0,0,1,1.7-1.7h24.582a1.7,1.7,0,0,1,1.7,1.7" transform="translate(-2584.85 19.54)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Контур_1346" data-name="Контур 1346" d="M2583.12-103.056h28.374v-.424H2583.12Z" transform="translate(-2583.12 103.48)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-119">
        <path id="Контур_1349" data-name="Контур 1349" d="M493.32-74.462c.109,1.493,2.215,1.508,4.6,1.232s1.754.326,5.1.374,4.5-1.06,4.661-1.537c.144-.424-1.467-.781-3.191-1.2l-.652-.162c-1.944-.494-3.231-1.949-4.894-3.22-1.575-1.2-3.607-5.558-3.607-5.558l-4.57-.207s1.925,3.9,2.648,5.587a5.515,5.515,0,0,1,.059,3.14,5.847,5.847,0,0,0-.158,1.551" transform="translate(-490.771 84.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-121">
        <path id="Контур_1352" data-name="Контур 1352" d="M512.037-24.736c2.389-.278,1.754.326,5.1.374s4.5-1.06,4.661-1.537c.144-.424-1.467-.781-3.191-1.2l-.021.031a9.382,9.382,0,0,1-4.909.88c-2.246-.226-4.537-1.162-6.086-1.33a5.849,5.849,0,0,0-.158,1.551c.109,1.493,2.215,1.508,4.6,1.232" transform="translate(-507.423 27.52)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Контур_1355" data-name="Контур 1355" d="M300.475-89.415c.108,1.493,2.214,1.511,4.6,1.237s1.754.329,5.1.378,4.5-1.054,4.662-1.533c.145-.424-1.466-.782-3.19-1.2-.217-.052-.434-.107-.652-.163-1.943-.5-3.229-1.952-4.891-3.223-1.574-1.2,0-8.338,0-8.338l-5.027-.49s.241,6.006.238,8.736a15.394,15.394,0,0,1-.683,3.047,5.85,5.85,0,0,0-.16,1.551" transform="translate(-300.466 102.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Контур_1358" data-name="Контур 1358" d="M305.078-22.7c2.39-.276,1.754.329,5.1.378s4.5-1.054,4.662-1.533c.145-.424-1.466-.782-3.19-1.2l-.021.032a9.385,9.385,0,0,1-4.91.874c-2.245-.227-4.536-1.167-6.085-1.336a5.85,5.85,0,0,0-.16,1.551c.108,1.493,2.214,1.511,4.6,1.237" transform="translate(-300.466 25.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Контур_1361" data-name="Контур 1361" d="M260.367-877.794c.865-3.559-.389-5.217-1.521-6.1a5.394,5.394,0,0,0-1.776-2.61,6.409,6.409,0,0,0-3.469-.664,1.621,1.621,0,0,1,.731-.347,5.377,5.377,0,0,0-1.919.5c-8.26-3.183-9.559,8.98-9.385,11.805s.149,6.153-4.148,8.38c-8.2,4.248,2.725,20.255,2.725,20.255-.208-2.283,10.327-14.333,15.824-17.942a15.891,15.891,0,0,0,1.849-5.724,15.025,15.025,0,0,1-1.125,5.178c2.505-2.306,2.876-7.842,2.213-12.733" transform="translate(-235.829 887.541)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <path id="Контур_1364" data-name="Контур 1364" d="M332.075-780.619c.028.012-2.654,1.4-4.671.853-1.9-.517-3.137-2.977-3.111-2.983.8-.16,1.035-1.264,1.018-6.008l.363.072,6.107,1.209s-.139,1.922-.143,3.733c0,1.52.088,2.962.436,3.125" transform="translate(-324.292 788.757)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Контур_1367" data-name="Контур 1367" d="M339.459-787.079s-.139,1.922-.143,3.733c-3.418-.1-5.224-3.214-5.964-4.942l6.107,1.209" transform="translate(-333.352 788.288)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-133">
        <path id="Контур_1370" data-name="Контур 1370" d="M340.874-445.835a1.5,1.5,0,0,1-1.358,1.952l-2.386.111-2.525.078a2.026,2.026,0,0,1-1.634-.746,70.986,70.986,0,0,1-11.98-22.683q-.159-.5-.336-1.019c-2.485-7.245-7.636-16.34-10.35-24.571-.432-1.312-.82-2.546-1.168-3.714-.111-.374-.22-.741-.323-1.1A87.33,87.33,0,0,1,305.7-513.92l19.6.157a431.75,431.75,0,0,0,15.572,67.928" transform="translate(-305.701 513.92)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-135">
        <path id="Контур_1373" data-name="Контур 1373" d="M295.843-727.887c-.049,9.328,1.368,13.33,1.368,13.33a26.338,26.338,0,0,1-18.367,1.126c-.118-.038-2.74-15.411-2.49-16.8a29.575,29.575,0,0,0,.243-7.815,62.712,62.712,0,0,0-2.419-12.272,63.707,63.707,0,0,0,8.092-2.766,6.322,6.322,0,0,0,4.365,1.619c1.987-.044,2.631-.894,2.528-1.823,2.117,1.178,3.807,2.291,3.807,2.291,1.8,3.126,5.449,6.162,5.714,10.957.065,1.179-2.839,11.894-2.84,12.155" transform="translate(-274.177 753.289)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Контур_1376" data-name="Контур 1376" d="M269.1-669.99c-.857,1.213-2.5,8.584-6.587,9.511,2.106-5.254,3.705-7.557,3.955-9.173a39.847,39.847,0,0,0,.242-9.078c1.368-2.4,2.389-4.042,2.389-4.042.108-.388,2.174-6.778,2.836-1.066s-1.879,12.491-2.836,13.847" transform="translate(-262.515 686.2)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <path id="Контур_1379" data-name="Контур 1379" d="M396.786-742.764a16.677,16.677,0,0,1,4.133,3.135c1.225,1.557,8.406,14.459,8.406,15.75s-7.286.448-7.286.448l-5.253-19.334" transform="translate(-396.786 742.764)" fill="#424857" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-140">
        <path id="Контур_1378" data-name="Контур 1378" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#424857"/>
      </clipPath>
      <clipPath id="clip-path-141">
        <path id="Контур_1382" data-name="Контур 1382" d="M335.413-844.932s-8.086.133-8.092-4.9-1.053-8.412,4.11-8.71,6.168,1.623,6.578,3.3-.493,10.145-2.6,10.31" transform="translate(-327.231 858.576)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-143">
        <path id="Контур_1385" data-name="Контур 1385" d="M320.667-864.871s-2.214,5.909-5.612,6.783-4.872-.037-4.872-.037a9.622,9.622,0,0,0,3.147-5.684s5.666-3.874,7.336-1.062" transform="translate(-310.184 865.895)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-145">
        <path id="Контур_1388" data-name="Контур 1388" d="M375.318-861.391a7.139,7.139,0,0,1,2.056,2.6c.4,1.283.584,3.923,1.354,3.833,0,0,1.668-4.016-.207-6.288-1.988-2.409-3.2-.147-3.2-.147" transform="translate(-375.318 862.355)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-147">
        <path id="Контур_1391" data-name="Контур 1391" d="M372.427-753.289s4.678,11.557,4.816,13.9.55,38.249.55,38.249-.275,1.65,2.889.962,3.99-1.376,3.99-1.376-3.027-22.151-2.2-29.856-6.329-20.271-6.329-20.271l-3.715-1.605" transform="translate(-372.427 753.289)" fill="#464e5f" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-148">
        <path id="Контур_1390" data-name="Контур 1390" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#464e5f"/>
      </clipPath>
      <clipPath id="clip-path-149">
        <path id="Контур_1394" data-name="Контур 1394" d="M354.431-364.862l-2.741.084a1.582,1.582,0,0,1-1.271-.573,70.743,70.743,0,0,1-12.126-22.861q-.159-.5-.336-1.019c-2.485-7.245-7.636-16.34-10.35-24.571-.432-1.312-.82-2.546-1.168-3.714-.111-.374-.22-.741-.323-1.1L329-420.81s8.666,14.033,11.349,27.24c2.331,11.471,11.664,25.274,14.08,28.707" transform="translate(-326.115 420.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-151">
        <path id="Контур_1397" data-name="Контур 1397" d="M263.6-413.036c.047,6.076-.241,19.619-.981,26.238a1.2,1.2,0,0,1-1.19,1.063h-2.045c-.862,0-1.507-.006-1.935-.012a.787.787,0,0,1-.759-.636c-2.034-10.35-3.49-18.948-4.555-26.378-.891-6.218-1.684-11.549-2.293-16.14-.614-4.618-1.04-8.486-1.193-11.753-.023-.514-.041-1.016-.051-1.5s-.012-.964-.008-1.425l16.937-1.65c-.213,11.253-1.954,28.462-1.926,32.194" transform="translate(-248.588 445.23)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-153">
        <path id="Контур_1400" data-name="Контур 1400" d="M259.724-360.31c-.774,0-1.373-.006-1.8-.011a.954.954,0,0,1-.922-.77c-2.02-10.291-3.468-18.847-4.529-26.246-.891-6.218-1.684-11.549-2.293-16.14-.614-4.618-1.04-8.486-1.193-11.753l6.215,3.656s-3.966,1.4-2.453,11.993,4.868,37.772,6.974,39.271" transform="translate(-248.988 415.23)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-155">
        <path id="Контур_1403" data-name="Контур 1403" d="M239.51-700.523s-1.193,4.31-5.32,3.76a49.747,49.747,0,0,1-10-3.028s1.764-5.573,3.957-12.9c2.655-8.865,5.942-20.291,7.492-27.5a44.567,44.567,0,0,0,.842-4.914l3.425-6.644s5.106,11.459,5.381,14.348-5.779,36.873-5.779,36.873" transform="translate(-224.193 751.745)" fill="#464e5f" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-157">
        <path id="Контур_1406" data-name="Контур 1406" d="M261.313-667.119c-.184,4.678-1.285,13.759-11.1,18.62-.026.012-.052.026-.078.037,2.655-8.865,5.942-20.291,7.492-27.5,1.8,2.694,3.786,6.269,3.686,8.844" transform="translate(-250.136 675.963)" fill="#3c4250" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-158">
        <path id="Контур_1405" data-name="Контур 1405" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#3c4250"/>
      </clipPath>
      <clipPath id="clip-path-159">
        <path id="Контур_1409" data-name="Контур 1409" d="M257.513-737.827l4.52-13.918a69.724,69.724,0,0,0-8.573,3.142s-1.791,2.309-2.9,7.636c0,0-1.171,12.789,1.443,17.466,0,0,8.255,1.65,21.463-2.889v-3.028s-14.446.756-17.4,1.1l1.451-9.511" transform="translate(-250.298 751.745)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-161">
        <path id="Контур_1412" data-name="Контур 1412" d="M259.594-751.745s-8.378.59-10.4,5.085-2.66,19.445-.092,23.848c0,0,3.536,1.007,9.789-.162l.231-.044s1.995-4.885.206-5.848c0,0-5.274.275-4.724-1.467s4.984-21.412,4.984-21.412" transform="translate(-247.422 751.745)" fill="#4f5767" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-162">
        <path id="Контур_1411" data-name="Контур 1411" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#4f5767"/>
      </clipPath>
      <clipPath id="clip-path-163">
        <path id="Контур_1415" data-name="Контур 1415" d="M386.539-616.853l-.247.793-13.747-4.277a.526.526,0,0,1-.093-.485.391.391,0,0,1,.34-.308l13.747,4.277" transform="translate(-372.427 621.13)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-165">
        <path id="Контур_1418" data-name="Контур 1418" d="M464.576-592.34l-.994-.75-.247.793,1.241-.043" transform="translate(-463.335 593.09)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-167">
        <path id="Контур_1421" data-name="Контур 1421" d="M395.1-614.548s3.119-1.92,4.427-1.624,4.643,2.71,3.039,3.233-2.9-.026-3.519.354-1.072,1.074-3.947,1.065,0-3.028,0-3.028" transform="translate(-393.822 616.203)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-169">
        <path id="Контур_1424" data-name="Контур 1424" d="M394.687-609.3l14.285.006,5.5-20.77-14.285-.006-5.5,20.77" transform="translate(-394.687 630.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-171">
        <path id="Контур_1427" data-name="Контур 1427" d="M430.687-639.45s-1.093.178-.618,1.431h4.878l-1.56-1.431h-2.7" transform="translate(-429.95 639.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-173">
        <path id="Контур_1430" data-name="Контур 1430" d="M434.786-639.45a3.306,3.306,0,0,1,2.9,1.734c.914,2.122.524,8.045.524,8.045h11.461s1.809-6.963-1.973-9.78H434.786" transform="translate(-434.786 639.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-175">
        <path id="Контур_1433" data-name="Контур 1433" d="M492.722-566.552s-1.725-1.292-2.259-.546.41,4.547,1.539,4.266,1.142-2.947.72-3.72" transform="translate(-490.31 567.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-177">
        <path id="Контур_1436" data-name="Контур 1436" d="M993.765-821.045l2.953-7.381c-2.616-2.432-3.718-5.863-3.879-9.814-.369-9.155,7.3-13.223,15.15-13.223s16.385,2.033,17.125,14.207c.476,7.832-3.7,12.651-15.72,12.651a22.47,22.47,0,0,1-9.619-1.78l-6.009,5.34" transform="translate(-992.826 851.463)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-179">
        <path id="Контур_1439" data-name="Контур 1439" d="M1920.907-915.7c.516-12.775-10.191-18.451-21.141-18.451s-22.863,2.837-23.9,19.824c-.663,10.928,5.163,17.653,21.937,17.653,5.659,0,10.056-.892,13.423-2.484l8.386,7.451-4.121-10.3c3.651-3.394,5.189-8.182,5.411-13.694" transform="translate(-1875.82 934.155)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-181">
        <path id="Контур_1442" data-name="Контур 1442" d="M740.781-281.7H697.264a2.993,2.993,0,0,1-2.993-2.992v-1.946h46.51v4.939" transform="translate(-694.271 286.64)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-183">
        <path id="Контур_1445" data-name="Контур 1445" d="M833.724-218.541h-2.808l-.787-35.719h4.287l-.692,35.719" transform="translate(-830.129 254.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-185">
        <path id="Контур_1448" data-name="Контур 1448" d="M781.992-40.748h-27.3v-2.466l13.914-1.255,13.385,1.255v2.466" transform="translate(-754.693 44.47)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-187">
        <path id="Контур_1451" data-name="Контур 1451" d="M737.306-35.085a2.245,2.245,0,0,1,2.245-2.245,2.245,2.245,0,0,1,2.246,2.245,2.245,2.245,0,0,1-2.246,2.245,2.245,2.245,0,0,1-2.245-2.245" transform="translate(-737.306 37.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-189">
        <path id="Контур_1454" data-name="Контур 1454" d="M916.29-35.085a2.245,2.245,0,0,1,2.245-2.245,2.245,2.245,0,0,1,2.247,2.245,2.245,2.245,0,0,1-2.247,2.245,2.245,2.245,0,0,1-2.245-2.245" transform="translate(-916.29 37.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-191">
        <path id="Контур_1457" data-name="Контур 1457" d="M789.81-251.917h-18.87a2.05,2.05,0,0,1-2.049-2.05v-.293h22.968v.293a2.049,2.049,0,0,1-2.048,2.05" transform="translate(-768.89 254.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-193">
        <path id="Контур_1460" data-name="Контур 1460" d="M698.168-510.9a3.9,3.9,0,0,1-3.9-3.9V-545.93c0-6.163,4.03-11.16,9-11.16h20.354c4.971,0,9,5,9,11.16V-510.9H698.168" transform="translate(-694.271 557.09)" fill="#43435c" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-195">
        <path id="Контур_1463" data-name="Контур 1463" d="M710.585-510.9a2.138,2.138,0,0,1-2.137-2.137V-545.93c0-6.163,4.03-11.16,9-11.16H737.8c4.972,0,9,5,9,11.16V-510.9H710.585" transform="translate(-708.448 557.09)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-197">
        <path id="Контур_1466" data-name="Контур 1466" d="M741.345-281.7H691.555a2.469,2.469,0,0,1-2.469-2.469,2.468,2.468,0,0,1,2.469-2.469h49.789a2.469,2.469,0,0,1,2.469,2.469,2.47,2.47,0,0,1-2.469,2.469" transform="translate(-689.086 286.64)" fill="#38384e" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-199">
        <path id="Контур_1469" data-name="Контур 1469" d="M1115.585-84.193c-.148,1.435,1.847,1.806,4.162,1.946s1.611.607,4.779,1.216,4.449-.246,4.684-.673c.209-.378-1.261-.99-2.826-1.679-.2-.085-.4-.174-.592-.262-1.762-.8-2.738-2.4-4.1-3.883-1.292-1.408-2.485-5.884-2.485-5.884l-4.3-.967s1.168,4.03,1.571,5.75a5.315,5.315,0,0,1-.474,2.989,5.577,5.577,0,0,0-.412,1.447" transform="translate(-1114.9 94.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-201">
        <path id="Контур_1472" data-name="Контур 1472" d="M1123.509-33.686c2.315.14,1.611.607,4.778,1.216s4.449-.246,4.684-.673c.209-.378-1.261-.99-2.826-1.679l-.026.027a9.039,9.039,0,0,1-4.807.006c-2.094-.593-4.11-1.87-5.553-2.291a5.583,5.583,0,0,0-.412,1.447c-.148,1.435,1.847,1.806,4.162,1.946" transform="translate(-1119.339 37.08)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-203">
        <path id="Контур_1475" data-name="Контур 1475" d="M846.017-425.966c-2.317-1.626-17.634-4.525-25.451-5.364l-8.64-7.5s-7.355,10.893-7.413,19.225c-.027,3.886,1.763,4.272,1.763,4.274s27.7.2,28.913.329c1.987.206,4.609,5.157,5.263,9.665s12.2,22.851,12.2,22.851a14.49,14.49,0,0,0,3.523.706,3.524,3.524,0,0,0,1.753-.471s-6.693-40.049-11.915-43.715" transform="translate(-804.512 438.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-205">
        <path id="Контур_1478" data-name="Контур 1478" d="M1068.613-84.193c-.148,1.435,1.849,1.806,4.164,1.946s1.609.607,4.778,1.216,4.449-.246,4.682-.673c.209-.378-1.26-.99-2.826-1.679-.2-.085-.394-.174-.59-.262-1.762-.8-2.739-2.4-4.1-3.883-1.292-1.408-2.484-5.884-2.484-5.884l-4.3-.967s1.168,4.03,1.569,5.75a5.314,5.314,0,0,1-.473,2.989,5.638,5.638,0,0,0-.413,1.447" transform="translate(-1067.93 94.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-207">
        <path id="Контур_1481" data-name="Контур 1481" d="M1076.531-33.686c2.315.14,1.609.607,4.778,1.216s4.449-.246,4.682-.673c.209-.378-1.26-.99-2.826-1.679l-.024.027a9.046,9.046,0,0,1-4.809.006c-2.093-.593-4.11-1.87-5.552-2.291a5.643,5.643,0,0,0-.413,1.447c-.148,1.435,1.849,1.806,4.164,1.946" transform="translate(-1072.359 37.08)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-209">
        <path id="Контур_1484" data-name="Контур 1484" d="M798.628-425.966c-2.012-1.412-8.062-2.509-14.743-3.322a4.492,4.492,0,0,1-3.813-3.371l-15.536-6.171s-7.355,10.893-7.413,19.225c-.027,3.886,1.763,4.272,1.763,4.274s27.7.2,28.912.329c1.987.206,4.609,5.157,5.263,9.665s12.206,22.851,12.206,22.851a14.473,14.473,0,0,0,3.522.706,3.516,3.516,0,0,0,1.752-.471s-6.693-40.049-11.915-43.715" transform="translate(-757.123 438.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-211">
        <path id="Контур_1487" data-name="Контур 1487" d="M831.113-755.465s-4.53,2.315-1.229,7.29c0,0-3.891.448-4.87-.67a3.167,3.167,0,0,0,.32.728s-3.77-.494-5.121-1.922,2.862-20.434,12.9-16.544c0,0,8.926.814,6.392,10.876s.8,10.621.8,10.621a4.814,4.814,0,0,1-1.451.226s-.61-.358-.679-.741a2.358,2.358,0,0,0,.171.8s-3.65-.262-5.216-1.92-2.883-6.723-2.016-8.741" transform="translate(-819.967 767.106)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-213">
        <path id="Контур_1490" data-name="Контур 1490" d="M913.214-701.494l-.361.05a2.973,2.973,0,0,1-3.343-2.529l-.264-1.9a2.975,2.975,0,0,1,2.529-3.343l.363-.05a2.973,2.973,0,0,1,3.343,2.528l.262,1.9a2.973,2.973,0,0,1-2.529,3.343" transform="translate(-909.217 709.29)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-215">
        <path id="Контур_1493" data-name="Контур 1493" d="M840.123-648.46c-1.351,1.043-2.207,4.811-4.387,5.431-8.167,2.332-12.552-7.368-13.986-12.1a7.119,7.119,0,0,1,2.756-1c.595-.1,1.22-.18,1.739-.232.892-.758,2.126-3.738,2.2-8.271l.393.085,6.606,1.451s-.194,2.085-.239,4.049c-.035,1.537-.085,1.9.034,2.088.514.085,1.12,1.4,1.12,1.4l0,.006a42.058,42.058,0,0,1,3.76,7.1" transform="translate(-821.75 664.638)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-217">
        <path id="Контур_1496" data-name="Контур 1496" d="M874.846-662.625s-.194,2.085-.24,4.051c-3.709-.182-5.6-3.607-6.366-5.5l6.606,1.45" transform="translate(-868.24 664.075)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-219">
        <path id="Контур_1499" data-name="Контур 1499" d="M816.783-586.56s-3.885.143-8.1.157c-.5,0-1,0-1.505,0a46.147,46.147,0,0,1-8.248-.5c-1.4-.36-6.8-4.019-11.122-8.453l0,0c-.334.8-.587,1.408-.593,1.457a22.37,22.37,0,0,1-.6,2.948c-.017.066-.034.133-.049.2s-.021.107-.032.162-.02.1-.027.156c-.464,2.844.95,7.158,1.362,9.349.131.7.16,1.177,0,1.333-7.036,6.671-20.234,5.155-26.028,4.08a2.14,2.14,0,0,1-1.517-3.072c2.234-4.419,6.426-14.18,5.748-24.129-.023-.34,3.144-11.2,3.144-11.2s2.461-.429,5.306-.659c.268.246.822,7.391,4.379,7.8.58.067,4.292,5.736,4.292,5.736l1.124-5.793-1.33-7.685a40.8,40.8,0,0,1,4.062,1.246,4.485,4.485,0,0,1,2.265,2.013c2.486,4.542,10.554,19.195,11.378,19.536a59.252,59.252,0,0,0,6.752,1.461h0c4.115.753,8.532,1.466,8.532,1.466l.81,2.395" transform="translate(-760.087 614.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-221">
        <path id="Контур_1502" data-name="Контур 1502" d="M917.783-414.058l-1.293,0,0,0,1.289,0" transform="translate(-916.49 414.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-223">
        <path id="Контур_1505" data-name="Контур 1505" d="M871.014-724.554s-8.784-.172-8.591-5.64-.813-9.173,4.8-9.294,6.632,2,7.011,3.844-.933,10.993-3.223,11.089" transform="translate(-862.42 739.492)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-225">
        <path id="Контур_1508" data-name="Контур 1508" d="M962.687-501.6c-.5,0-1,0-1.507,0a46.241,46.241,0,0,1-8.247-.506c-1.4-.361-6.8-4.02-11.122-8.453l0,0a30.611,30.611,0,0,1,1.3-4.074c.392-.584,1.943,5.091,5.564,7.4s9.287,3.927,12.038,3.273c1.855-.441,2.044,1.225,1.971,2.361" transform="translate(-941.81 514.675)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-227">
        <path id="Контур_1511" data-name="Контур 1511" d="M907.46-662.61s.056.655,1.5.758c0,0,1.209.087,1.26-.618a3.551,3.551,0,0,0-2.756-.141" transform="translate(-907.46 662.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-229">
        <path id="Контур_1514" data-name="Контур 1514" d="M875.976-683.009a9.654,9.654,0,0,1-6.226-6.348l.532-.132-.265.066.265-.066a9.088,9.088,0,0,0,5.86,5.957l.095.029-.16.525-.1-.031" transform="translate(-869.75 689.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-231">
        <path id="Контур_1517" data-name="Контур 1517" d="M909.893-650.365l-.47-.148a.459.459,0,0,1-.3-.573.46.46,0,0,1,.573-.3l.471.15a.457.457,0,0,1,.3.573.458.458,0,0,1-.573.3" transform="translate(-909.104 651.406)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-233">
        <path id="Контур_1520" data-name="Контур 1520" d="M865.844-749.214s-2.248,6.661-5.622,7.79-4.492-.208-4.492-.208a9.756,9.756,0,0,0,2.771-5.977s5.45-4.329,7.342-1.606" transform="translate(-855.73 750.114)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-235">
        <path id="Контур_1523" data-name="Контур 1523" d="M894.51-743.882s4.579-.257,5.947,1.047c.987.94.964,1.431,1.04,2.685,0,0,2-3.089-.058-5.251-2.184-2.293-6.929,1.52-6.929,1.52" transform="translate(-894.51 746.136)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-237">
        <path id="Контур_1526" data-name="Контур 1526" d="M891.1-748.353s-1.147,6.373-5.338,8.688c0,0,4.06-4.156,4.132-8.313s1.206-.374,1.206-.374" transform="translate(-885.76 749.929)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-239">
        <path id="Контур_1529" data-name="Контур 1529" d="M820.213-750.042c1.35,1.429,5.119,1.925,5.119,1.925a3.108,3.108,0,0,1-.317-.729c1.937,1.569,6.694.43,6.694.43-3.795-.839-2.565-6.316-1.275-6.549a118.11,118.11,0,0,1,2.944-11.583c-.169-.027-.264-.036-.264-.036-10.039-3.889-14.251,15.117-12.9,16.543" transform="translate(-819.967 767.109)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-241">
        <path id="Контур_1532" data-name="Контур 1532" d="M853.422-705.178l-.363.05a2.971,2.971,0,0,1-3.342-2.529l-.264-1.9a2.972,2.972,0,0,1,2.529-3.343l.363-.05a2.971,2.971,0,0,1,3.342,2.529l.264,1.9a2.972,2.972,0,0,1-2.529,3.343" transform="translate(-849.425 712.973)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-243">
        <path id="Контур_1535" data-name="Контур 1535" d="M859.876-758.459c-.215-.4.54-3.543,1.652-5.978a7.37,7.37,0,0,1,2.861-3.158,2.083,2.083,0,0,1,1.193,0,9.124,9.124,0,0,1,1.046.422c-3.18.992-5.944,7.657-5.744,8.347a.243.243,0,0,0-.021-.049l-.987.417" transform="translate(-859.84 767.685)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-245">
        <path id="Контур_1538" data-name="Контур 1538" d="M859.787-695.8l-.18.025a1.483,1.483,0,0,1-1.667-1.26l-.13-.944a1.48,1.48,0,0,1,1.26-1.666l.18-.025a1.481,1.481,0,0,1,1.665,1.26l.131.944a1.482,1.482,0,0,1-1.26,1.666" transform="translate(-857.796 699.688)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-247">
        <path id="Контур_1541" data-name="Контур 1541" d="M1010.58-451.3a28.584,28.584,0,0,1,4.132-.02,20.593,20.593,0,0,1,2.935,2,21.044,21.044,0,0,1,1.147,1.9s-.358.1-.522-.046-1.391-1.452-1.391-1.452l1.263,1.919a1.07,1.07,0,0,1-.61-.21c-.172-.191-1.353-1.389-1.353-1.389l-.4-.079a4.368,4.368,0,0,1,.631.625,3.979,3.979,0,0,1,.369.616,3.007,3.007,0,0,1-.473-.114c-.111-.053-1.17-.979-1.17-.979l.561.766a1.481,1.481,0,0,1-.5-.1,7.876,7.876,0,0,0-1.1-.84,4.931,4.931,0,0,1-1.649-.282c-.451-.239-1.87-.787-1.87-.787l0-1.521" transform="translate(-1010.58 451.401)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-249">
        <path id="Контур_1544" data-name="Контур 1544" d="M840.922-621.61a11.879,11.879,0,0,0-3.092,1.313s2.633,6.731,6.429,9.873l1.014-.72v1.637l3.8,2.486s-8.346-12.552-8.152-14.589" transform="translate(-837.83 621.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-251">
        <path id="Контур_1547" data-name="Контур 1547" d="M827.824-514.4c-1.993-.772-8.674-3.476-11.36-6-3-2.826-9.228-12.984-9.228-12.984l10.9-2.663s4.489,6.552,5.767,7.8c.981.959,3.268,1.031,3.268,1.031l-.6,2.948c-.017.066-.034.133-.049.2l-.032.162c-.011.05-.018.1-.027.156-.464,2.845.95,7.158,1.362,9.35" transform="translate(-807.236 536.05)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-253">
        <path id="Контур_1550" data-name="Контур 1550" d="M909.861-620.5s1.159,9.159.206,13.478c0,0,1.556-2.858,1.774-3.381l-.262-1.571,1,.349s-.885-7.3-1.685-8.582-1.1-1.4-1.1-1.4l.061,1.11" transform="translate(-909.8 621.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-255">
        <path id="Контур_1553" data-name="Контур 1553" d="M1075.848-451.428s-4.859.178-9.609.157c-1.177-1.829.264-4.019.264-4.019h0c4.115.752,8.532,1.466,8.532,1.466l.81,2.4" transform="translate(-1065.776 455.29)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-257">
        <path id="Контур_1556" data-name="Контур 1556" d="M1035.048-421.979H1017.09a1.431,1.431,0,0,1,1.431-1.431h16.527v1.431" transform="translate(-1017.09 423.41)" fill="#323248" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-258">
        <path id="Контур_1555" data-name="Контур 1555" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#323248"/>
      </clipPath>
      <clipPath id="clip-path-259">
        <path id="Контур_1559" data-name="Контур 1559" d="M1124.254-542.26l-6.659,19.558H1089.65l6.839-19.558h27.765" transform="translate(-1089.65 542.26)" fill="#44445d" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-260">
        <path id="Контур_1558" data-name="Контур 1558" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#44445d"/>
      </clipPath>
      <clipPath id="clip-path-261">
        <path id="Контур_1562" data-name="Контур 1562" d="M1126.525-542.26l-6.6,19.378-.061.18H1092.33l6.839-19.558h27.356" transform="translate(-1092.33 542.26)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-263">
        <path id="Контур_1565" data-name="Контур 1565" d="M1205.834-486.847a1.8,1.8,0,0,1-1.666,1.353,1.041,1.041,0,0,1-1.04-1.353,1.8,1.8,0,0,1,1.666-1.353,1.042,1.042,0,0,1,1.04,1.353" transform="translate(-1203.092 488.2)" fill="#2d2d40" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-264">
        <path id="Контур_1564" data-name="Контур 1564" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#2d2d40"/>
      </clipPath>
      <clipPath id="clip-path-265">
        <path id="Контур_1568" data-name="Контур 1568" d="M1209.57-542.26l-6.6,19.378H1190.32l15.426-19.378h3.824" transform="translate(-1190.32 542.26)" fill="#2d2d40" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-267">
        <path id="Контур_1571" data-name="Контур 1571" d="M930.844-465.439s-9.244.807-14.354.58v-5.021c3.348.924,14.351,2.919,14.351,2.919l0,1.522" transform="translate(-916.49 469.88)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-269">
        <path id="Контур_1574" data-name="Контур 1574" d="M796.8-613s-5.287.12-6.924,1.887,1.624,11.756,1.624,11.756,9.854,12.069,14.784,12.92c0,0,2.155.294,3.16.311,0,0-.985-2.872.848-4.673,0,0-2.111-.59-2.2-.853S796.8-613,796.8-613" transform="translate(-789.422 613)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-271">
        <path id="Контур_1577" data-name="Контур 1577" d="M629.152-339.663H629.1c-.283,0-.511-.418-.5-.927l.764-59.549,2.252-.471-1.92,60.025c-.017.519-.255.923-.544.923" transform="translate(-628.6 400.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-273">
        <path id="Контур_1580" data-name="Контур 1580" d="M595.987-410.3H727.366a1.533,1.533,0,0,0,1.533-1.533v-.528a1.533,1.533,0,0,0-1.533-1.533H595.987a1.533,1.533,0,0,0-1.533,1.533v.528a1.533,1.533,0,0,0,1.533,1.533" transform="translate(-594.454 413.89)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-275">
        <path id="Контур_1583" data-name="Контур 1583" d="M2517.235-339.663h.047c.284,0,.511-.418.5-.927l-.764-59.549-2.253-.471,1.92,60.025c.017.519.256.923.544.923" transform="translate(-2514.77 400.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-277">
        <path id="Контур_1586" data-name="Контур 1586" d="M1392.268-410.3h175.269a1.768,1.768,0,0,0,1.768-1.768v-.058a1.768,1.768,0,0,0-1.768-1.768H1392.268a1.768,1.768,0,0,0-1.768,1.768v.058a1.768,1.768,0,0,0,1.768,1.768" transform="translate(-1390.5 413.89)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-279">
        <path id="Контур_1589" data-name="Контур 1589" d="M1615.239-421.979H1597.28a1.432,1.432,0,0,1,1.432-1.431h16.527v1.431" transform="translate(-1597.28 423.41)" fill="#323248" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-281">
        <path id="Контур_1592" data-name="Контур 1592" d="M1704.444-542.26l-6.659,19.558H1669.84l6.841-19.558h27.763" transform="translate(-1669.84 542.26)" fill="#2d2d40" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-283">
        <path id="Контур_1595" data-name="Контур 1595" d="M1706.715-542.26l-6.6,19.378-.061.18H1672.52l6.841-19.558h27.354" transform="translate(-1672.52 542.26)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-285">
        <path id="Контур_1598" data-name="Контур 1598" d="M1786.026-486.847a1.8,1.8,0,0,1-1.666,1.353,1.041,1.041,0,0,1-1.04-1.353,1.8,1.8,0,0,1,1.665-1.353,1.041,1.041,0,0,1,1.04,1.353" transform="translate(-1783.285 488.2)" fill="#2d2d40" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-287">
        <path id="Контур_1601" data-name="Контур 1601" d="M1789.76-542.26l-6.6,19.378H1770.51l15.427-19.378h3.822" transform="translate(-1770.51 542.26)" fill="#2d2d40" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-289">
        <path id="Контур_1604" data-name="Контур 1604" d="M1929.88-542.26l6.7,19.579,27.9-.021-6.841-19.558H1929.88" transform="translate(-1929.88 542.26)" fill="#b5b5c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-290">
        <path id="Контур_1603" data-name="Контур 1603" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-291">
        <path id="Контур_1607" data-name="Контур 1607" d="M1935.1-539.45l6.444,18.952.06.177h26.9l-6.682-19.129H1935.1" transform="translate(-1935.1 539.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-293">
        <path id="Контур_1610" data-name="Контур 1610" d="M1971.61-421.958l38.969-.021a1.432,1.432,0,0,0-1.432-1.431H1971.21l.4,1.452" transform="translate(-1971.21 423.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-295">
        <path id="Контур_1613" data-name="Контур 1613" d="M2288.53-675.407h-.366a2.971,2.971,0,0,1-2.963-2.964v-1.913a2.972,2.972,0,0,1,2.963-2.964h.366a2.972,2.972,0,0,1,2.963,2.964v1.913a2.971,2.971,0,0,1-2.963,2.964" transform="translate(-2285.2 683.248)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-297">
        <path id="Контур_1616" data-name="Контур 1616" d="M2120.824-57.3s-.392,6.127-1.579,6.212c-.157.012-6.012,0-6.012,0l-7.473-.059A2.424,2.424,0,0,1,2108-53.323c2.186-.375,5.846-1.9,7.176-4.788,1.4-3.035,5.651.813,5.651.813" transform="translate(-2105.76 59.277)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-299">
        <path id="Контур_1619" data-name="Контур 1619" d="M2186.576-376.71c.287.241,7.9,8.489,7.277,14.823a3.656,3.656,0,0,1-3.656,3.261h-9.272s-9.461,1.69-9.687,3.735-2.163,13.065-6,28.729c0,0-2.828.973-4.542.278-2.268-.92-2.6-1.894-2.6-1.894s2.286-35.072,4.092-37.54,20.406-9.806,26.429-10.536-2.045-.856-2.045-.856" transform="translate(-2158.1 376.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-301">
        <path id="Контур_1622" data-name="Контур 1622" d="M1944.6-437.149s-5.381-1.251-6.757,0a6.791,6.791,0,0,0-1.815,3.066h8.572v-3.066" transform="translate(-1936.03 437.704)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-303">
        <path id="Контур_1625" data-name="Контур 1625" d="M2034.936-57.3s-.393,6.127-1.58,6.212c-.157.012-6.012,0-6.012,0l-7.474-.059a2.426,2.426,0,0,1,2.239-2.176c2.186-.375,5.845-1.9,7.175-4.788,1.4-3.035,5.652.813,5.652.813" transform="translate(-2019.87 59.277)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-305">
        <path id="Контур_1628" data-name="Контур 1628" d="M2122.895-376.71a50.957,50.957,0,0,1,2.964,9.65,6.947,6.947,0,0,1-6.783,8.434H2097.5s-9.461,1.69-9.687,3.735-2.163,13.065-6,28.729a9.033,9.033,0,0,1-5.053-.2,6.255,6.255,0,0,1-2.106-1.711s2.306-34.775,4.112-37.242,20.406-9.806,26.429-10.536,17.694-.856,17.694-.856" transform="translate(-2074.66 376.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-307">
        <path id="Контур_1631" data-name="Контур 1631" d="M2262.394-628.637l-3.336,3.2-5.684,0c-.006-.458.014-2.362.033-4.091,0-.1,0-.2,0-.3,0-.384.01-.753.014-1.091.012-.952.021-1.641.021-1.641l4.707-3.223,1.756-1.2c0,.031.146,1.6.168,1.829a13.313,13.313,0,0,0,2.318,6.514" transform="translate(-2253.373 636.98)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-309">
        <path id="Контур_1634" data-name="Контур 1634" d="M2245.916-600.413c.108-.046,6.258-2.8,6.978-2.567s1.615,2.434,1.615,2.434L2243.68-598.1a5.38,5.38,0,0,1,2.236-2.317" transform="translate(-2243.68 602.994)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-311">
        <path id="Контур_1637" data-name="Контур 1637" d="M2059.417-568.377c-1.171-7.225-6.119-12.633-8.236-14.66-1.421-2.138-9.77-3.9-9.77-3.9l-10.829,2.45a13.8,13.8,0,0,0-9.545,6.354c-2.129,3.52-8.226,11.573-12.229,11.823s-16.577,2.69-16.577,2.69v3.093L2011.4-560a3.247,3.247,0,0,0,.7-.056c1.649-.311,8.72-1.716,12.237-3.546.561,9.055,1.225,22.883,4.388,25.079,3.345,2.323,26.868,2.632,29.36-.908,1.275-1.81-.288-12.537-2.242-22.881,2.428-1.155,4.068-3.032,3.576-6.061" transform="translate(-1992.23 586.94)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-313">
        <path id="Контур_1640" data-name="Контур 1640" d="M2259.285-616.7l-.29.18a23.26,23.26,0,0,1-5.285,2.791c.012-.952.021-1.641.021-1.641l1.461-1,4.092-.329" transform="translate(-2253.71 616.7)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-315">
        <path id="Контур_1643" data-name="Контур 1643" d="M2229.538-688.4s8.746,1.034,9.305-4.422,2.068-9-3.493-9.888-6.86,1.079-7.49,2.855-.583,11.045,1.677,11.455" transform="translate(-2227.513 702.934)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-317">
        <path id="Контур_1646" data-name="Контур 1646" d="M2224.639-717.164s1.65,5.919,2.846,5.425,2.288-.9,2.445.95.851,4.014,4.746,3.619,5.532-11.065,4.129-12.741c-4.049-4.836-19.791-3.024-14.166,2.747" transform="translate(-2223.438 722.695)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-319">
        <path id="Контур_1649" data-name="Контур 1649" d="M2190.7-277.73h43.26a2.974,2.974,0,0,0,2.974-2.976v-1.934H2190.7v4.91" transform="translate(-2190.7 282.64)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-321">
        <path id="Контур_1652" data-name="Контур 1652" d="M2144.49-277.73h32.728a2.2,2.2,0,0,0,2.2-2.2v-.511a2.2,2.2,0,0,0-2.2-2.2H2144.49a2.2,2.2,0,0,0-2.2,2.2v.511a2.2,2.2,0,0,0,2.2,2.2" transform="translate(-2142.29 282.64)" fill="#38384e" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-323">
        <path id="Контур_1655" data-name="Контур 1655" d="M2331.52-214.943h2.79l.784-35.507h-4.263l.689,35.507" transform="translate(-2330.83 250.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-325">
        <path id="Контур_1658" data-name="Контур 1658" d="M2255.84-38.2h27.138v-2.451L2269.146-41.9l-13.306,1.248V-38.2" transform="translate(-2255.84 41.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-327">
        <path id="Контур_1661" data-name="Контур 1661" d="M2426.244-32.577a2.232,2.232,0,0,0-2.231-2.233,2.232,2.232,0,0,0-2.233,2.233,2.232,2.232,0,0,0,2.233,2.231,2.231,2.231,0,0,0,2.231-2.231" transform="translate(-2421.78 34.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-329">
        <path id="Контур_1664" data-name="Контур 1664" d="M2248.316-32.577a2.233,2.233,0,0,0-2.233-2.233,2.233,2.233,0,0,0-2.233,2.233,2.233,2.233,0,0,0,2.233,2.231,2.233,2.233,0,0,0,2.233-2.231" transform="translate(-2243.85 34.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-331">
        <path id="Контур_1667" data-name="Контур 1667" d="M2249.271-248.121h25.192a2.33,2.33,0,0,0,2.329-2.329H2246.94a2.33,2.33,0,0,0,2.331,2.329" transform="translate(-2246.94 250.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-333">
        <path id="Контур_1670" data-name="Контур 1670" d="M2265.762-505.575a2.125,2.125,0,0,0,2.125-2.125v-32.7c0-6.127-4.007-11.094-8.947-11.094h-20.233c-4.942,0-8.947,4.968-8.947,11.094v34.82h36" transform="translate(-2229.76 551.49)" fill="#464660" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-334">
        <path id="Контур_1669" data-name="Контур 1669" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#464660"/>
      </clipPath>
      <clipPath id="clip-path-335">
        <path id="Контур_1673" data-name="Контур 1673" d="M2278.1-505.575a3.874,3.874,0,0,0,3.874-3.874V-540.4c0-6.127-4.005-11.094-8.947-11.094H2252.8c-4.942,0-8.948,4.968-8.948,11.094v34.82H2278.1" transform="translate(-2243.85 551.49)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-337">
        <path id="Контур_1676" data-name="Контур 1676" d="M2218.969-642.891a9.649,9.649,0,0,0,6.228-6.348l-.532-.131.265.066-.265-.066a9.092,9.092,0,0,1-5.86,5.957l-.095.029.16.525.1-.032" transform="translate(-2218.71 649.37)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-339">
        <path id="Контур_1679" data-name="Контур 1679" d="M2213.4-610.249l.47-.149a.459.459,0,0,0,.3-.573.458.458,0,0,0-.573-.3l-.471.148a.458.458,0,0,0-.3.573.46.46,0,0,0,.573.3" transform="translate(-2212.81 611.29)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-341">
        <path id="Контур_1682" data-name="Контур 1682" d="M2239.532-662.3l-.362.05a2.973,2.973,0,0,1-3.343-2.527l-.264-1.9a2.972,2.972,0,0,1,2.529-3.343l.363-.05a2.973,2.973,0,0,1,3.343,2.529l.262,1.9a2.972,2.972,0,0,1-2.529,3.342" transform="translate(-2235.535 670.094)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-343">
        <path id="Контур_1685" data-name="Контур 1685" d="M2245.986-715.58c-.215-.4.54-3.543,1.652-5.978a7.37,7.37,0,0,1,2.861-3.158,2.083,2.083,0,0,1,1.193,0,6.814,6.814,0,0,1,1.046.51c-3.18.991-5.944,7.569-5.744,8.259a.25.25,0,0,0-.021-.049l-.987.417" transform="translate(-2245.95 724.805)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-345">
        <path id="Контур_1688" data-name="Контур 1688" d="M2245.9-652.927l-.18.024a1.481,1.481,0,0,1-1.666-1.26l-.131-.944a1.479,1.479,0,0,1,1.26-1.666l.18-.026a1.482,1.482,0,0,1,1.666,1.26l.131.946a1.483,1.483,0,0,1-1.26,1.666" transform="translate(-2243.906 656.811)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-347">
        <path id="Контур_1691" data-name="Контур 1691" d="M482.168-7.894v.163a.49.49,0,0,1-.5.476H42.279a.49.49,0,0,1-.5-.476v-.163a.49.49,0,0,1,.5-.476h439.39a.49.49,0,0,1,.5.476" transform="translate(-41.78 8.37)" fill="#cddff0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-348">
        <path id="Контур_1690" data-name="Контур 1690" d="M-168-1355.959H320.066V-1661H-168Z" transform="translate(168 1661)" fill="#cddff0"/>
      </clipPath>
    </defs>
    <g id="office-group" transform="translate(-0.873 1311.518)">
      <g id="Сгруппировать_963" data-name="Сгруппировать 963" transform="translate(0.873 -1311.518)" clip-path="url(#clip-path)">
        <g id="Сгруппировать_962" data-name="Сгруппировать 962" transform="translate(-25.757 -53.303)" clip-path="url(#clip-path-2)">
          <path id="Контур_1170" data-name="Контур 1170" d="M-4.127-1316.518H443.651v201.525H-4.127Z" transform="translate(29.121 1369.059)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Сгруппировать_965" data-name="Сгруппировать 965" transform="translate(212.77 -1155.203)" clip-path="url(#clip-path-3)">
        <g id="Сгруппировать_964" data-name="Сгруппировать 964" transform="translate(-237.653 -209.618)" clip-path="url(#clip-path-2)">
          <path id="Контур_1173" data-name="Контур 1173" d="M1385.17-291.64H1433.2v6.464H1385.17Z" transform="translate(-1148.28 500.496)" fill="#77a3e4"/>
        </g>
      </g>
      <g id="Сгруппировать_967" data-name="Сгруппировать 967" transform="translate(233.491 -1150.264)" clip-path="url(#clip-path-5)">
        <g id="Сгруппировать_966" data-name="Сгруппировать 966" transform="translate(-258.375 -214.557)" clip-path="url(#clip-path-2)">
          <path id="Контур_1176" data-name="Контур 1176" d="M1521.03-259.26h5.814v37.244h-5.814Z" transform="translate(-1263.418 473.054)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_969" data-name="Сгруппировать 969" transform="translate(221.987 -1118.267)" clip-path="url(#clip-path-7)">
        <g id="Сгруппировать_968" data-name="Сгруппировать 968" transform="translate(-246.87 -246.554)" clip-path="url(#clip-path-2)">
          <path id="Контур_1179" data-name="Контур 1179" d="M1445.6-49.47h28.823v5.247H1445.6Z" transform="translate(-1199.493 295.262)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_971" data-name="Сгруппировать 971" transform="translate(219.334 -1117.178)" clip-path="url(#clip-path-9)">
        <g id="Сгруппировать_970" data-name="Сгруппировать 970" transform="translate(-244.218 -247.643)" clip-path="url(#clip-path-2)">
          <path id="Контур_1182" data-name="Контур 1182" d="M1428.21-42.33h6.017v6.015h-6.017Z" transform="translate(-1184.755 289.211)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_973" data-name="Сгруппировать 973" transform="translate(246.634 -1117.178)" clip-path="url(#clip-path-11)">
        <g id="Сгруппировать_972" data-name="Сгруппировать 972" transform="translate(-271.517 -247.643)" clip-path="url(#clip-path-2)">
          <path id="Контур_1185" data-name="Контур 1185" d="M1607.2-42.33h6.016v6.015H1607.2Z" transform="translate(-1336.445 289.211)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_975" data-name="Сгруппировать 975" transform="translate(224.151 -1150.264)" clip-path="url(#clip-path-13)">
        <g id="Сгруппировать_974" data-name="Сгруппировать 974" transform="translate(-249.034 -214.557)" clip-path="url(#clip-path-2)">
          <path id="Контур_1188" data-name="Контур 1188" d="M1459.79-259.26h24.495v3.868H1459.79Z" transform="translate(-1211.518 473.054)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_977" data-name="Сгруппировать 977" transform="translate(212.77 -1196.452)" clip-path="url(#clip-path-15)">
        <g id="Сгруппировать_976" data-name="Сгруппировать 976" transform="translate(-237.653 -168.369)" clip-path="url(#clip-path-16)">
          <path id="Контур_1191" data-name="Контур 1191" d="M1385.17-562.09h39.88v47.713h-39.88Z" transform="translate(-1148.28 729.696)" fill="#43435c"/>
        </g>
      </g>
      <g id="Сгруппировать_979" data-name="Сгруппировать 979" transform="translate(214.932 -1196.452)" clip-path="url(#clip-path-17)">
        <g id="Сгруппировать_978" data-name="Сгруппировать 978" transform="translate(-239.816 -168.369)" clip-path="url(#clip-path-18)">
          <path id="Контур_1194" data-name="Контур 1194" d="M1399.35-562.09h39.88v47.713h-39.88Z" transform="translate(-1160.297 729.696)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_981" data-name="Сгруппировать 981" transform="translate(211.98 -1155.203)" clip-path="url(#clip-path-19)">
        <g id="Сгруппировать_980" data-name="Сгруппировать 980" transform="translate(-236.863 -209.618)" clip-path="url(#clip-path-20)">
          <path id="Контур_1197" data-name="Контур 1197" d="M1379.99-291.64h56.253v6.464H1379.99Z" transform="translate(-1143.89 500.496)" fill="#38384e"/>
        </g>
      </g>
      <g id="Сгруппировать_983" data-name="Сгруппировать 983" transform="translate(245.305 -1275.915)" clip-path="url(#clip-path-21)">
        <g id="Сгруппировать_982" data-name="Сгруппировать 982" transform="translate(-270.189 -88.906)" clip-path="url(#clip-path-2)">
          <path id="Контур_1200" data-name="Контур 1200" d="M1598.49-1083.09h23.54v22.882h-23.54Z" transform="translate(-1329.064 1171.233)" fill="#c0d7fa"/>
        </g>
      </g>
      <g id="Сгруппировать_985" data-name="Сгруппировать 985" transform="translate(246.26 -1274.99)" clip-path="url(#clip-path-23)">
        <g id="Сгруппировать_984" data-name="Сгруппировать 984" transform="translate(-271.144 -89.831)" clip-path="url(#clip-path-2)">
          <path id="Контур_1203" data-name="Контур 1203" d="M1604.75-1077.024h21.63v21.031h-21.63Z" transform="translate(-1334.369 1166.092)" fill="#dfebfc"/>
        </g>
      </g>
      <g id="Сгруппировать_987" data-name="Сгруппировать 987" transform="translate(255.857 -1273.497)" clip-path="url(#clip-path-25)">
        <g id="Сгруппировать_986" data-name="Сгруппировать 986" transform="translate(-280.74 -91.324)" clip-path="url(#clip-path-2)">
          <path id="Контур_1206" data-name="Контур 1206" d="M1667.67-1067.234h2.437v2.437h-2.437Z" transform="translate(-1387.693 1157.796)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_989" data-name="Сгруппировать 989" transform="translate(259.478 -1272.631)" clip-path="url(#clip-path-27)">
        <g id="Сгруппировать_988" data-name="Сгруппировать 988" transform="translate(-284.362 -92.19)" clip-path="url(#clip-path-2)">
          <path id="Контур_1209" data-name="Контур 1209" d="M1691.415-1061.556h2.438v2.437h-2.437Z" transform="translate(-1407.816 1152.984)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_991" data-name="Сгруппировать 991" transform="translate(262.48 -1269.965)" clip-path="url(#clip-path-29)">
        <g id="Сгруппировать_990" data-name="Сгруппировать 990" transform="translate(-287.363 -94.856)" clip-path="url(#clip-path-2)">
          <path id="Контур_1212" data-name="Контур 1212" d="M1711.1-1044.076h2.437v2.438H1711.1Z" transform="translate(-1424.495 1138.17)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_993" data-name="Сгруппировать 993" transform="translate(263.794 -1266.016)" clip-path="url(#clip-path-31)">
        <g id="Сгруппировать_992" data-name="Сгруппировать 992" transform="translate(-288.677 -98.805)" clip-path="url(#clip-path-2)">
          <path id="Контур_1215" data-name="Контур 1215" d="M1719.71-1018.183h2.437v2.437h-2.437Z" transform="translate(-1431.795 1116.226)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_995" data-name="Сгруппировать 995" transform="translate(262.675 -1261.651)" clip-path="url(#clip-path-33)">
        <g id="Сгруппировать_994" data-name="Сгруппировать 994" transform="translate(-287.558 -103.171)" clip-path="url(#clip-path-2)">
          <path id="Контур_1218" data-name="Контур 1218" d="M1712.372-989.563h2.437v2.437h-2.437Z" transform="translate(-1425.577 1091.971)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_997" data-name="Сгруппировать 997" transform="translate(259.811 -1258.78)" clip-path="url(#clip-path-35)">
        <g id="Сгруппировать_996" data-name="Сгруппировать 996" transform="translate(-284.694 -106.041)" clip-path="url(#clip-path-2)">
          <path id="Контур_1221" data-name="Контур 1221" d="M1693.593-970.74h2.438v2.437h-2.438Z" transform="translate(-1409.662 1076.019)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_999" data-name="Сгруппировать 999" transform="translate(255.857 -1257.622)" clip-path="url(#clip-path-37)">
        <g id="Сгруппировать_998" data-name="Сгруппировать 998" transform="translate(-280.74 -107.199)" clip-path="url(#clip-path-2)">
          <path id="Контур_1224" data-name="Контур 1224" d="M1667.67-963.152h2.437v2.437h-2.437Z" transform="translate(-1387.693 1069.588)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1001" data-name="Сгруппировать 1001" transform="translate(252.22 -1272.631)" clip-path="url(#clip-path-39)">
        <g id="Сгруппировать_1000" data-name="Сгруппировать 1000" transform="translate(-277.103 -92.19)" clip-path="url(#clip-path-2)">
          <path id="Контур_1227" data-name="Контур 1227" d="M1643.824-1061.556h2.438v2.437h-2.438Z" transform="translate(-1367.483 1152.984)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1003" data-name="Сгруппировать 1003" transform="translate(249.219 -1269.965)" clip-path="url(#clip-path-41)">
        <g id="Сгруппировать_1002" data-name="Сгруппировать 1002" transform="translate(-274.102 -94.856)" clip-path="url(#clip-path-2)">
          <path id="Контур_1230" data-name="Контур 1230" d="M1624.149-1044.076h2.438v2.438h-2.437Z" transform="translate(-1350.809 1138.17)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1005" data-name="Сгруппировать 1005" transform="translate(247.904 -1266.016)" clip-path="url(#clip-path-43)">
        <g id="Сгруппировать_1004" data-name="Сгруппировать 1004" transform="translate(-272.788 -98.805)" clip-path="url(#clip-path-2)">
          <path id="Контур_1233" data-name="Контур 1233" d="M1615.53-1018.183h2.437v2.437h-2.437Z" transform="translate(-1343.505 1116.226)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1007" data-name="Сгруппировать 1007" transform="translate(249.024 -1261.651)" clip-path="url(#clip-path-45)">
        <g id="Сгруппировать_1006" data-name="Сгруппировать 1006" transform="translate(-273.908 -103.171)" clip-path="url(#clip-path-2)">
          <path id="Контур_1236" data-name="Контур 1236" d="M1622.872-989.563h2.438v2.437h-2.438Z" transform="translate(-1349.727 1091.971)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1009" data-name="Сгруппировать 1009" transform="translate(251.889 -1258.78)" clip-path="url(#clip-path-47)">
        <g id="Сгруппировать_1008" data-name="Сгруппировать 1008" transform="translate(-276.772 -106.041)" clip-path="url(#clip-path-2)">
          <path id="Контур_1239" data-name="Контур 1239" d="M1641.653-970.74h2.437v2.437h-2.437Z" transform="translate(-1365.644 1076.019)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1011" data-name="Сгруппировать 1011" transform="translate(256.189 -1266.016)" clip-path="url(#clip-path-49)">
        <g id="Сгруппировать_1010" data-name="Сгруппировать 1010" transform="translate(-281.073 -98.805)" clip-path="url(#clip-path-2)">
          <path id="Контур_1242" data-name="Контур 1242" d="M1669.85-1018.183h2.437v2.437h-2.437Z" transform="translate(-1389.54 1116.226)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1013" data-name="Сгруппировать 1013" transform="translate(256.584 -1271.256)" clip-path="url(#clip-path-51)">
        <g id="Сгруппировать_1012" data-name="Сгруппировать 1012" transform="translate(-281.468 -93.565)" clip-path="url(#clip-path-2)">
          <path id="Контур_1245" data-name="Контур 1245" d="M1672.44-1052.543h1.649v7.222h-1.649Z" transform="translate(-1391.735 1145.345)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1015" data-name="Сгруппировать 1015" transform="translate(256.473 -1268.085)" clip-path="url(#clip-path-53)">
        <g id="Сгруппировать_1014" data-name="Сгруппировать 1014" transform="translate(-281.356 -96.736)" clip-path="url(#clip-path-2)">
          <path id="Контур_1248" data-name="Контур 1248" d="M1671.71-1031.753h4.776v4.19h-4.776Z" transform="translate(-1391.116 1127.726)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1017" data-name="Сгруппировать 1017" transform="translate(273.083 -1121.846)" clip-path="url(#clip-path-55)">
        <g id="Сгруппировать_1016" data-name="Сгруппировать 1016" transform="translate(-297.967 -242.975)" clip-path="url(#clip-path-2)">
          <path id="Контур_1251" data-name="Контур 1251" d="M1780.616-72.936h21.34v11.565h-21.34Z" transform="translate(-1483.412 315.149)" fill="#1b2f51"/>
        </g>
      </g>
      <g id="Сгруппировать_1019" data-name="Сгруппировать 1019" transform="translate(230.464 -1169.665)" clip-path="url(#clip-path-57)">
        <g id="Сгруппировать_1018" data-name="Сгруппировать 1018" transform="translate(-255.347 -195.156)" clip-path="url(#clip-path-2)">
          <path id="Контур_1254" data-name="Контур 1254" d="M1501.183-386.46h53.223v52.5h-53.223Z" transform="translate(-1246.598 580.854)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1021" data-name="Сгруппировать 1021" transform="translate(261.419 -1121.284)" clip-path="url(#clip-path-59)">
        <g id="Сгруппировать_1020" data-name="Сгруппировать 1020" transform="translate(-286.303 -243.537)" clip-path="url(#clip-path-2)">
          <path id="Контур_1257" data-name="Контур 1257" d="M1704.141-69.252H1724.8v11h-20.662Z" transform="translate(-1418.601 312.026)" fill="#1e3b68"/>
        </g>
      </g>
      <g id="Сгруппировать_1023" data-name="Сгруппировать 1023" transform="translate(224.661 -1170.727)" clip-path="url(#clip-path-61)">
        <g id="Сгруппировать_1022" data-name="Сгруппировать 1022" transform="translate(-249.545 -194.095)" clip-path="url(#clip-path-2)">
          <path id="Контур_1260" data-name="Контур 1260" d="M1463.138-393.421h47.651v54.679h-47.651Z" transform="translate(-1214.356 586.753)" fill="#22417b"/>
        </g>
      </g>
      <g id="Сгруппировать_1025" data-name="Сгруппировать 1025" transform="translate(234.359 -1217.177)" clip-path="url(#clip-path-63)">
        <g id="Сгруппировать_1024" data-name="Сгруппировать 1024" transform="translate(-259.243 -147.644)" clip-path="url(#clip-path-2)">
          <path id="Контур_1263" data-name="Контур 1263" d="M1526.721-697.971h8.4v9.648h-8.4Z" transform="translate(-1268.241 844.853)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1027" data-name="Сгруппировать 1027" transform="translate(220.74 -1205.357)" clip-path="url(#clip-path-65)">
        <g id="Сгруппировать_1026" data-name="Сгруппировать 1026" transform="translate(-245.624 -159.464)" clip-path="url(#clip-path-2)">
          <path id="Контур_1266" data-name="Контур 1266" d="M1437.429-620.475h32.829v46.518h-32.829Z" transform="translate(-1192.568 779.176)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1029" data-name="Сгруппировать 1029" transform="translate(241.039 -1204.615)" clip-path="url(#clip-path-67)">
        <g id="Сгруппировать_1028" data-name="Сгруппировать 1028" transform="translate(-265.922 -160.206)" clip-path="url(#clip-path-2)">
          <path id="Контур_1269" data-name="Контур 1269" d="M1570.515-615.61h38.419v29.16h-38.419Z" transform="translate(-1305.356 775.054)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1031" data-name="Сгруппировать 1031" transform="translate(228.183 -1210.261)" clip-path="url(#clip-path-69)">
        <g id="Сгруппировать_1030" data-name="Сгруппировать 1030" transform="translate(-253.066 -154.56)" clip-path="url(#clip-path-2)">
          <path id="Контур_1272" data-name="Контур 1272" d="M1486.224-652.63h11.515v11.673h-11.515Z" transform="translate(-1233.921 806.427)" fill="#ea9e6a"/>
        </g>
      </g>
      <g id="Сгруппировать_1033" data-name="Сгруппировать 1033" transform="translate(228.775 -1210.106)" clip-path="url(#clip-path-71)">
        <g id="Сгруппировать_1032" data-name="Сгруппировать 1032" transform="translate(-253.659 -154.715)" clip-path="url(#clip-path-2)">
          <path id="Контур_1275" data-name="Контур 1275" d="M1490.11-651.61h10.922v7.559H1490.11Z" transform="translate(-1237.214 805.563)" fill="#d78d58"/>
        </g>
      </g>
      <g id="Сгруппировать_1035" data-name="Сгруппировать 1035" transform="translate(227.02 -1222.699)" clip-path="url(#clip-path-73)">
        <g id="Сгруппировать_1034" data-name="Сгруппировать 1034" transform="translate(-251.904 -142.123)" clip-path="url(#clip-path-2)">
          <path id="Контур_1278" data-name="Контур 1278" d="M1478.6-734.175h14.7v17.159h-14.7Z" transform="translate(-1227.464 875.535)" fill="#ea9e6a"/>
        </g>
      </g>
      <g id="Сгруппировать_1037" data-name="Сгруппировать 1037" transform="translate(224.455 -1226.077)" clip-path="url(#clip-path-75)">
        <g id="Сгруппировать_1036" data-name="Сгруппировать 1036" transform="translate(-249.338 -138.745)" clip-path="url(#clip-path-2)">
          <path id="Контур_1281" data-name="Контур 1281" d="M1461.782-756.322h18.309v18h-18.309Z" transform="translate(-1213.207 894.304)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1039" data-name="Сгруппировать 1039" transform="translate(221.236 -1187.191)" clip-path="url(#clip-path-77)">
        <g id="Сгруппировать_1038" data-name="Сгруппировать 1038" transform="translate(-246.12 -177.63)" clip-path="url(#clip-path-2)">
          <path id="Контур_1284" data-name="Контур 1284" d="M1440.68-501.369h12.172v22.728H1440.68Z" transform="translate(-1195.323 678.237)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1041" data-name="Сгруппировать 1041" transform="translate(226.556 -1177.9)" clip-path="url(#clip-path-79)">
        <g id="Сгруппировать_1040" data-name="Сгруппировать 1040" transform="translate(-251.439 -186.921)" clip-path="url(#clip-path-2)">
          <path id="Контур_1287" data-name="Контур 1287" d="M1475.56-440.456H1487.3v4.84H1475.56Z" transform="translate(-1224.883 626.614)" fill="#ea9e6a"/>
        </g>
      </g>
      <g id="Сгруппировать_1043" data-name="Сгруппировать 1043" transform="translate(203.646 -1202.347)" clip-path="url(#clip-path-81)">
        <g id="Сгруппировать_1042" data-name="Сгруппировать 1042" transform="translate(-228.529 -162.474)" clip-path="url(#clip-path-2)">
          <path id="Контур_1290" data-name="Контур 1290" d="M1325.35-600.74h26.411v29.287H1325.35Z" transform="translate(-1097.583 762.452)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1045" data-name="Сгруппировать 1045" transform="translate(249.326 -1184.165)" clip-path="url(#clip-path-83)">
        <g id="Сгруппировать_1044" data-name="Сгруппировать 1044" transform="translate(-274.209 -180.657)" clip-path="url(#clip-path-2)">
          <path id="Контур_1293" data-name="Контур 1293" d="M1624.85-481.527h28.656v8.709H1624.85Z" transform="translate(-1351.403 661.421)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1047" data-name="Сгруппировать 1047" transform="translate(226.494 -1217.177)" clip-path="url(#clip-path-85)">
        <g id="Сгруппировать_1046" data-name="Сгруппировать 1046" transform="translate(-251.378 -147.644)" clip-path="url(#clip-path-2)">
          <path id="Контур_1296" data-name="Контур 1296" d="M1475.154-697.971h8.4v9.648h-8.4Z" transform="translate(-1224.539 844.853)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1049" data-name="Сгруппировать 1049" transform="translate(227.762 -1226.077)" clip-path="url(#clip-path-87)">
        <g id="Сгруппировать_1048" data-name="Сгруппировать 1048" transform="translate(-252.646 -138.745)" clip-path="url(#clip-path-2)">
          <path id="Контур_1299" data-name="Контур 1299" d="M1483.469-756.322h6.52v11.445h-6.52Z" transform="translate(-1231.586 894.304)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1051" data-name="Сгруппировать 1051" transform="translate(229.315 -1213.187)" clip-path="url(#clip-path-89)">
        <g id="Сгруппировать_1050" data-name="Сгруппировать 1050" transform="translate(-254.199 -151.634)" clip-path="url(#clip-path-2)">
          <path id="Контур_1302" data-name="Контур 1302" d="M1493.65-671.813h9.729v6.13h-9.729Z" transform="translate(-1240.214 822.684)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1053" data-name="Сгруппировать 1053" transform="translate(227.844 -1215.063)" clip-path="url(#clip-path-91)">
        <g id="Сгруппировать_1052" data-name="Сгруппировать 1052" transform="translate(-252.727 -149.758)" clip-path="url(#clip-path-2)">
          <path id="Контур_1305" data-name="Контур 1305" d="M1484-684.112h4.95v5.573H1484Z" transform="translate(-1232.038 833.108)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1055" data-name="Сгруппировать 1055" transform="translate(237.101 -1209.337)" clip-path="url(#clip-path-93)">
        <g id="Сгруппировать_1054" data-name="Сгруппировать 1054" transform="translate(-261.985 -155.484)" clip-path="url(#clip-path-2)">
          <path id="Контур_1308" data-name="Контур 1308" d="M1544.7-646.57h2.977v2.468H1544.7Z" transform="translate(-1283.478 801.292)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1057" data-name="Сгруппировать 1057" transform="translate(410.901 -1141.835)" clip-path="url(#clip-path-95)">
        <g id="Сгруппировать_1056" data-name="Сгруппировать 1056" transform="translate(-435.785 -222.987)" clip-path="url(#clip-path-2)">
          <path id="Контур_1311" data-name="Контур 1311" d="M2684.219-203.991h21.665v15.777h-21.665Z" transform="translate(-2249.197 426.215)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1059" data-name="Сгруппировать 1059" transform="translate(409.509 -1137.007)" clip-path="url(#clip-path-97)">
        <g id="Сгруппировать_1058" data-name="Сгруппировать 1058" transform="translate(-434.393 -227.814)" clip-path="url(#clip-path-2)">
          <path id="Контур_1314" data-name="Контур 1314" d="M2675.09-172.341h20.651v9.376H2675.09Z" transform="translate(-2241.46 399.392)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1061" data-name="Сгруппировать 1061" transform="translate(406.779 -1130.335)" clip-path="url(#clip-path-99)">
        <g id="Сгруппировать_1060" data-name="Сгруппировать 1060" transform="translate(-431.662 -234.486)" clip-path="url(#clip-path-2)">
          <path id="Контур_1317" data-name="Контур 1317" d="M2657.19-128.6h5.314v7.766h-5.314Z" transform="translate(-2226.29 362.32)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1063" data-name="Сгруппировать 1063" transform="translate(381.101 -1152.478)" clip-path="url(#clip-path-101)">
        <g id="Сгруппировать_1062" data-name="Сгруппировать 1062" transform="translate(-405.984 -212.343)" clip-path="url(#clip-path-2)">
          <path id="Контур_1320" data-name="Контур 1320" d="M2488.831-273.777h24.647V-254.1h-24.647Z" transform="translate(-2083.609 485.357)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1065" data-name="Сгруппировать 1065" transform="translate(384.031 -1148.899)" clip-path="url(#clip-path-103)">
        <g id="Сгруппировать_1064" data-name="Сгруппировать 1064" transform="translate(-408.914 -215.922)" clip-path="url(#clip-path-2)">
          <path id="Контур_1323" data-name="Контур 1323" d="M2508.04-250.31h19.629v18.061H2508.04Z" transform="translate(-2099.889 465.469)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1067" data-name="Сгруппировать 1067" transform="translate(401.419 -1134.098)" clip-path="url(#clip-path-105)">
        <g id="Сгруппировать_1066" data-name="Сгруппировать 1066" transform="translate(-426.302 -230.723)" clip-path="url(#clip-path-2)">
          <path id="Контур_1326" data-name="Контур 1326" d="M2622.047-153.264h3.638v9.962h-3.638Z" transform="translate(-2196.507 383.225)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_1069" data-name="Сгруппировать 1069" transform="translate(404.581 -1161.012)" clip-path="url(#clip-path-107)">
        <g id="Сгруппировать_1068" data-name="Сгруппировать 1068" transform="translate(-429.464 -203.809)" clip-path="url(#clip-path-2)">
          <path id="Контур_1329" data-name="Контур 1329" d="M2642.777-329.727h32.005v25.448h-32.005Z" transform="translate(-2214.076 532.773)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_1071" data-name="Сгруппировать 1071" transform="translate(407.334 -1156.293)" clip-path="url(#clip-path-109)">
        <g id="Сгруппировать_1070" data-name="Сгруппировать 1070" transform="translate(-432.218 -208.528)" clip-path="url(#clip-path-2)">
          <path id="Контур_1332" data-name="Контур 1332" d="M2660.83-298.79h25.39v23.322h-25.39Z" transform="translate(-2229.375 506.555)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_1073" data-name="Сгруппировать 1073" transform="translate(405.492 -1136.783)" clip-path="url(#clip-path-111)">
        <g id="Сгруппировать_1072" data-name="Сгруппировать 1072" transform="translate(-430.375 -228.038)" clip-path="url(#clip-path-2)">
          <path id="Контур_1335" data-name="Контур 1335" d="M2648.752-170.869h4.31v12.647h-4.31Z" transform="translate(-2219.139 398.145)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_1075" data-name="Сгруппировать 1075" transform="translate(395.172 -1127.267)" clip-path="url(#clip-path-113)">
        <g id="Сгруппировать_1074" data-name="Сгруппировать 1074" transform="translate(-420.056 -237.554)" clip-path="url(#clip-path-2)">
          <path id="Контур_1338" data-name="Контур 1338" d="M2581.09-108.48h29.133v15.33H2581.09Z" transform="translate(-2161.797 345.271)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_1077" data-name="Сгруппировать 1077" transform="translate(394.983 -1114.465)" clip-path="url(#clip-path-115)">
        <g id="Сгруппировать_1076" data-name="Сгруппировать 1076" transform="translate(-419.866 -250.357)" clip-path="url(#clip-path-2)">
          <path id="Контур_1341" data-name="Контур 1341" d="M2579.85-24.54h29.513v3.229H2579.85Z" transform="translate(-2160.746 274.134)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_1079" data-name="Сгруппировать 1079" transform="translate(394.719 -1127.267)" clip-path="url(#clip-path-117)">
        <g id="Сгруппировать_1078" data-name="Сгруппировать 1078" transform="translate(-419.603 -237.554)" clip-path="url(#clip-path-2)">
          <path id="Контур_1344" data-name="Контур 1344" d="M2578.12-108.48h29.9v1.949h-29.9Z" transform="translate(-2159.28 345.271)" fill="#9fc1f4"/>
        </g>
      </g>
      <g id="Сгруппировать_1081" data-name="Сгруппировать 1081" transform="translate(75.593 -1124.409)" clip-path="url(#clip-path-119)">
        <g id="Сгруппировать_1080" data-name="Сгруппировать 1080" transform="translate(-100.476 -240.412)" clip-path="url(#clip-path-2)">
          <path id="Контур_1347" data-name="Контур 1347" d="M485.771-89.74h18.448v13.411H485.771Z" transform="translate(-386.057 329.39)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1083" data-name="Сгруппировать 1083" transform="translate(78.133 -1115.682)" clip-path="url(#clip-path-121)">
        <g id="Сгруппировать_1082" data-name="Сгруппировать 1082" transform="translate(-103.016 -249.139)" clip-path="url(#clip-path-2)">
          <path id="Контур_1350" data-name="Контур 1350" d="M502.423-32.52h15.908v4.684H502.423Z" transform="translate(-400.17 280.897)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1085" data-name="Сгруппировать 1085" transform="translate(46.567 -1127.156)" clip-path="url(#clip-path-123)">
        <g id="Сгруппировать_1084" data-name="Сгруппировать 1084" transform="translate(-71.451 -237.665)" clip-path="url(#clip-path-2)">
          <path id="Контур_1353" data-name="Контур 1353" d="M295.466-107.75h15.908v16.477H295.466Z" transform="translate(-224.778 344.653)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1087" data-name="Сгруппировать 1087" transform="translate(46.567 -1115.372)" clip-path="url(#clip-path-125)">
        <g id="Сгруппировать_1086" data-name="Сгруппировать 1086" transform="translate(-71.451 -249.449)" clip-path="url(#clip-path-2)">
          <path id="Контур_1356" data-name="Контур 1356" d="M295.466-30.49h15.908V-25.8H295.466Z" transform="translate(-224.778 279.176)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1089" data-name="Сгруппировать 1089" transform="translate(36.709 -1246.853)" clip-path="url(#clip-path-127)">
        <g id="Сгруппировать_1088" data-name="Сгруппировать 1088" transform="translate(-61.592 -117.968)" clip-path="url(#clip-path-2)">
          <path id="Контур_1359" data-name="Контур 1359" d="M230.829-892.541h26.353v42.492H230.829Z" transform="translate(-169.999 1009.747)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_1091" data-name="Сгруппировать 1091" transform="translate(50.201 -1231.786)" clip-path="url(#clip-path-129)">
        <g id="Сгруппировать_1090" data-name="Сгруппировать 1090" transform="translate(-75.085 -133.035)" clip-path="url(#clip-path-2)">
          <path id="Контур_1362" data-name="Контур 1362" d="M319.292-793.757H328.6v10.646h-9.308Z" transform="translate(-244.97 926.029)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1093" data-name="Сгруппировать 1093" transform="translate(51.583 -1231.715)" clip-path="url(#clip-path-131)">
        <g id="Сгруппировать_1092" data-name="Сгруппировать 1092" transform="translate(-76.467 -133.107)" clip-path="url(#clip-path-2)">
          <path id="Контур_1365" data-name="Контур 1365" d="M328.352-793.288h7.633v6.467h-7.633Z" transform="translate(-252.648 925.632)" fill="#ec965d"/>
        </g>
      </g>
      <g id="Сгруппировать_1095" data-name="Сгруппировать 1095" transform="translate(47.366 -1189.868)" clip-path="url(#clip-path-133)">
        <g id="Сгруппировать_1094" data-name="Сгруппировать 1094" transform="translate(-72.249 -174.953)" clip-path="url(#clip-path-2)">
          <path id="Контур_1368" data-name="Контур 1368" d="M300.7-518.92h36.77v71.753H300.7Z" transform="translate(-229.214 693.111)" fill="#2a4e95"/>
        </g>
      </g>
      <g id="Сгруппировать_1097" data-name="Сгруппировать 1097" transform="translate(42.558 -1226.376)" clip-path="url(#clip-path-135)">
        <g id="Сгруппировать_1096" data-name="Сгруппировать 1096" transform="translate(-67.441 -138.445)" clip-path="url(#clip-path-2)">
          <path id="Контур_1371" data-name="Контур 1371" d="M269.177-758.289h26.032v42.536H269.177Z" transform="translate(-202.498 895.971)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1099" data-name="Сгруппировать 1099" transform="translate(40.779 -1216.144)" clip-path="url(#clip-path-137)">
        <g id="Сгруппировать_1098" data-name="Сгруппировать 1098" transform="translate(-65.662 -148.677)" clip-path="url(#clip-path-2)">
          <path id="Контур_1374" data-name="Контур 1374" d="M257.515-691.2h11.057v27.246H257.515Z" transform="translate(-192.615 839.114)" fill="#5f9aa8"/>
        </g>
      </g>
      <g id="Сгруппировать_1101" data-name="Сгруппировать 1101" transform="translate(61.258 -1224.771)" clip-path="url(#clip-path-139)">
        <g id="Сгруппировать_1100" data-name="Сгруппировать 1100" transform="translate(-86.142 -140.05)" clip-path="url(#clip-path-140)">
          <path id="Контур_1377" data-name="Контур 1377" d="M391.786-747.764H405.85v21.13H391.786Z" transform="translate(-306.407 887.051)" fill="#424857"/>
        </g>
      </g>
      <g id="Сгруппировать_1103" data-name="Сгруппировать 1103" transform="translate(50.65 -1242.435)" clip-path="url(#clip-path-141)">
        <g id="Сгруппировать_1102" data-name="Сгруппировать 1102" transform="translate(-75.533 -122.386)" clip-path="url(#clip-path-2)">
          <path id="Контур_1380" data-name="Контур 1380" d="M322.231-863.576h12.4v15.17h-12.4Z" transform="translate(-247.46 985.2)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1105" data-name="Сгруппировать 1105" transform="translate(48.05 -1243.551)" clip-path="url(#clip-path-143)">
        <g id="Сгруппировать_1104" data-name="Сгруппировать 1104" transform="translate(-72.933 -121.27)" clip-path="url(#clip-path-2)">
          <path id="Контур_1383" data-name="Контур 1383" d="M305.184-870.895h12.008v9.711H305.184Z" transform="translate(-233.014 991.402)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_1107" data-name="Сгруппировать 1107" transform="translate(57.984 -1243.011)" clip-path="url(#clip-path-145)">
        <g id="Сгруппировать_1106" data-name="Сгруппировать 1106" transform="translate(-82.867 -121.81)" clip-path="url(#clip-path-2)">
          <path id="Контур_1386" data-name="Контур 1386" d="M370.318-867.355h5.619v8.926h-5.619Z" transform="translate(-288.213 988.402)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_1109" data-name="Сгруппировать 1109" transform="translate(57.543 -1226.376)" clip-path="url(#clip-path-147)">
        <g id="Сгруппировать_1108" data-name="Сгруппировать 1108" transform="translate(-82.426 -138.445)" clip-path="url(#clip-path-148)">
          <path id="Контур_1389" data-name="Контур 1389" d="M367.427-758.289H381.2v54.8H367.427Z" transform="translate(-285.763 895.971)" fill="#464e5f"/>
        </g>
      </g>
      <g id="Сгруппировать_1111" data-name="Сгруппировать 1111" transform="translate(50.479 -1175.667)" clip-path="url(#clip-path-149)">
        <g id="Сгруппировать_1110" data-name="Сгруппировать 1110" transform="translate(-75.363 -189.155)" clip-path="url(#clip-path-2)">
          <path id="Контур_1392" data-name="Контур 1392" d="M321.115-425.81h29.841v57.557H321.115Z" transform="translate(-246.515 614.202)" fill="#1e3c6f"/>
        </g>
      </g>
      <g id="Сгруппировать_1113" data-name="Сгруппировать 1113" transform="translate(38.655 -1179.391)" clip-path="url(#clip-path-151)">
        <g id="Сгруппировать_1112" data-name="Сгруппировать 1112" transform="translate(-63.538 -185.43)" clip-path="url(#clip-path-2)">
          <path id="Контур_1395" data-name="Контур 1395" d="M243.588-450.23h18.464v61.02H243.588Z" transform="translate(-180.812 634.897)" fill="#2a4e95"/>
        </g>
      </g>
      <g id="Сгруппировать_1115" data-name="Сгруппировать 1115" transform="translate(38.716 -1174.816)" clip-path="url(#clip-path-153)">
        <g id="Сгруппировать_1114" data-name="Сгруппировать 1114" transform="translate(-63.599 -190.006)" clip-path="url(#clip-path-2)">
          <path id="Контур_1398" data-name="Контур 1398" d="M243.988-420.23h12.261v56.445H243.988Z" transform="translate(-181.151 609.473)" fill="#1e3c6f"/>
        </g>
      </g>
      <g id="Сгруппировать_1117" data-name="Сгруппировать 1117" transform="translate(34.934 -1226.141)" clip-path="url(#clip-path-155)">
        <g id="Сгруппировать_1116" data-name="Сгруппировать 1116" transform="translate(-59.818 -138.68)" clip-path="url(#clip-path-148)">
          <path id="Контур_1401" data-name="Контур 1401" d="M219.193-756.745h22.63v56.555h-22.63Z" transform="translate(-160.138 894.663)" fill="#464e5f"/>
        </g>
      </g>
      <g id="Сгруппировать_1119" data-name="Сгруппировать 1119" transform="translate(38.891 -1214.583)" clip-path="url(#clip-path-157)">
        <g id="Сгруппировать_1118" data-name="Сгруппировать 1118" transform="translate(-63.774 -150.238)" clip-path="url(#clip-path-158)">
          <path id="Контур_1404" data-name="Контур 1404" d="M245.136-680.963h12.706v29.025H245.136Z" transform="translate(-182.124 830.439)" fill="#3c4250"/>
        </g>
      </g>
      <g id="Сгруппировать_1121" data-name="Сгруппировать 1121" transform="translate(38.916 -1226.141)" clip-path="url(#clip-path-159)">
        <g id="Сгруппировать_1120" data-name="Сгруппировать 1120" transform="translate(-63.799 -138.68)" clip-path="url(#clip-path-2)">
          <path id="Контур_1407" data-name="Контур 1407" d="M245.3-756.745h24.693v30.062H245.3Z" transform="translate(-182.262 894.663)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1123" data-name="Сгруппировать 1123" transform="translate(38.477 -1226.141)" clip-path="url(#clip-path-161)">
        <g id="Сгруппировать_1122" data-name="Сгруппировать 1122" transform="translate(-63.36 -138.68)" clip-path="url(#clip-path-162)">
          <path id="Контур_1410" data-name="Контур 1410" d="M242.422-756.745H256.6v30.861H242.422Z" transform="translate(-179.824 894.663)" fill="#4f5767"/>
        </g>
      </g>
      <g id="Сгруппировать_1125" data-name="Сгруппировать 1125" transform="translate(57.543 -1206.219)" clip-path="url(#clip-path-163)">
        <g id="Сгруппировать_1124" data-name="Сгруппировать 1124" transform="translate(-82.426 -158.602)" clip-path="url(#clip-path-2)">
          <path id="Контур_1413" data-name="Контур 1413" d="M367.427-626.13h15.637v6.595H367.427Z" transform="translate(-285.763 783.969)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_1127" data-name="Сгруппировать 1127" transform="translate(71.408 -1201.943)" clip-path="url(#clip-path-165)">
        <g id="Сгруппировать_1126" data-name="Сгруппировать 1126" transform="translate(-96.292 -162.878)" clip-path="url(#clip-path-2)">
          <path id="Контур_1416" data-name="Контур 1416" d="M458.335-598.09H461.1v2.318h-2.766Z" transform="translate(-362.806 760.206)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_1129" data-name="Сгруппировать 1129" transform="translate(60.806 -1205.468)" clip-path="url(#clip-path-167)">
        <g id="Сгруппировать_1128" data-name="Сгруппировать 1128" transform="translate(-85.689 -159.353)" clip-path="url(#clip-path-2)">
          <path id="Контур_1419" data-name="Контур 1419" d="M388.822-621.2h10.7V-615h-10.7Z" transform="translate(-303.895 779.794)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1131" data-name="Сгруппировать 1131" transform="translate(60.938 -1207.583)" clip-path="url(#clip-path-169)">
        <g id="Сгруппировать_1130" data-name="Сгруппировать 1130" transform="translate(-85.821 -157.238)" clip-path="url(#clip-path-2)">
          <path id="Контур_1422" data-name="Контур 1422" d="M389.687-635.07H411v22.3H389.687Z" transform="translate(-304.628 791.545)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1133" data-name="Сгруппировать 1133" transform="translate(66.316 -1209.014)" clip-path="url(#clip-path-171)">
        <g id="Сгруппировать_1132" data-name="Сгруппировать 1132" transform="translate(-91.2 -155.807)" clip-path="url(#clip-path-2)">
          <path id="Контур_1425" data-name="Контур 1425" d="M424.95-644.45h6.523v2.956H424.95Z" transform="translate(-334.513 799.495)" fill="#9daff4"/>
        </g>
      </g>
      <g id="Сгруппировать_1135" data-name="Сгруппировать 1135" transform="translate(67.054 -1209.014)" clip-path="url(#clip-path-173)">
        <g id="Сгруппировать_1134" data-name="Сгруппировать 1134" transform="translate(-91.937 -155.807)" clip-path="url(#clip-path-2)">
          <path id="Контур_1428" data-name="Контур 1428" d="M429.786-644.45h16.843v11.3H429.786Z" transform="translate(-338.611 799.495)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_1137" data-name="Сгруппировать 1137" transform="translate(75.522 -1198.014)" clip-path="url(#clip-path-175)">
        <g id="Сгруппировать_1136" data-name="Сгруппировать 1136" transform="translate(-100.406 -166.807)" clip-path="url(#clip-path-2)">
          <path id="Контур_1431" data-name="Контур 1431" d="M485.31-572.33H489.5v6.038H485.31Z" transform="translate(-385.667 738.375)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1139" data-name="Сгруппировать 1139" transform="translate(152.167 -1241.35)" clip-path="url(#clip-path-177)">
        <g id="Сгруппировать_1138" data-name="Сгруппировать 1138" transform="translate(-177.05 -123.471)" clip-path="url(#clip-path-2)">
          <path id="Контур_1434" data-name="Контур 1434" d="M987.826-856.463h33.849v31.943H987.826Z" transform="translate(-811.539 979.172)" fill="#deebfa"/>
        </g>
      </g>
      <g id="Сгруппировать_1141" data-name="Сгруппировать 1141" transform="translate(286.841 -1253.962)" clip-path="url(#clip-path-179)">
        <g id="Сгруппировать_1140" data-name="Сгруппировать 1140" transform="translate(-311.725 -110.859)" clip-path="url(#clip-path-2)">
          <path id="Контур_1437" data-name="Контур 1437" d="M1870.82-939.155h46.63v43.969h-46.63Z" transform="translate(-1559.858 1049.251)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1143" data-name="Сгруппировать 1143" transform="translate(106.631 -1155.203)" clip-path="url(#clip-path-181)">
        <g id="Сгруппировать_1142" data-name="Сгруппировать 1142" transform="translate(-131.514 -209.618)" clip-path="url(#clip-path-2)">
          <path id="Контур_1440" data-name="Контур 1440" d="M689.271-291.64h48.035v6.464H689.271Z" transform="translate(-558.519 500.496)" fill="#77a3e4"/>
        </g>
      </g>
      <g id="Сгруппировать_1145" data-name="Сгруппировать 1145" transform="translate(127.352 -1150.264)" clip-path="url(#clip-path-183)">
        <g id="Сгруппировать_1144" data-name="Сгруппировать 1144" transform="translate(-152.235 -214.557)" clip-path="url(#clip-path-2)">
          <path id="Контур_1443" data-name="Контур 1443" d="M825.129-259.26h5.813v37.244h-5.813Z" transform="translate(-673.656 473.054)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_1147" data-name="Сгруппировать 1147" transform="translate(115.846 -1118.267)" clip-path="url(#clip-path-185)">
        <g id="Сгруппировать_1146" data-name="Сгруппировать 1146" transform="translate(-140.73 -246.554)" clip-path="url(#clip-path-2)">
          <path id="Контур_1446" data-name="Контур 1446" d="M749.693-49.47h28.824v5.247H749.693Z" transform="translate(-609.726 295.262)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_1149" data-name="Сгруппировать 1149" transform="translate(113.194 -1117.178)" clip-path="url(#clip-path-187)">
        <g id="Сгруппировать_1148" data-name="Сгруппировать 1148" transform="translate(-138.078 -247.643)" clip-path="url(#clip-path-2)">
          <path id="Контур_1449" data-name="Контур 1449" d="M732.306-42.33h6.016v6.015h-6.016Z" transform="translate(-594.991 289.211)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_1151" data-name="Сгруппировать 1151" transform="translate(140.493 -1117.178)" clip-path="url(#clip-path-189)">
        <g id="Сгруппировать_1150" data-name="Сгруппировать 1150" transform="translate(-165.377 -247.643)" clip-path="url(#clip-path-2)">
          <path id="Контур_1452" data-name="Контур 1452" d="M911.29-42.33h6.017v6.015H911.29Z" transform="translate(-746.676 289.211)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_1153" data-name="Сгруппировать 1153" transform="translate(118.012 -1150.264)" clip-path="url(#clip-path-191)">
        <g id="Сгруппировать_1152" data-name="Сгруппировать 1152" transform="translate(-142.895 -214.557)" clip-path="url(#clip-path-2)">
          <path id="Контур_1455" data-name="Контур 1455" d="M763.89-259.26h24.493v3.868H763.89Z" transform="translate(-621.758 473.054)" fill="#93afec"/>
        </g>
      </g>
      <g id="Сгруппировать_1155" data-name="Сгруппировать 1155" transform="translate(106.631 -1196.452)" clip-path="url(#clip-path-193)">
        <g id="Сгруппировать_1154" data-name="Сгруппировать 1154" transform="translate(-131.514 -168.369)" clip-path="url(#clip-path-16)">
          <path id="Контур_1458" data-name="Контур 1458" d="M689.271-562.09H729.15v47.713H689.271Z" transform="translate(-558.519 729.696)" fill="#43435c"/>
        </g>
      </g>
      <g id="Сгруппировать_1157" data-name="Сгруппировать 1157" transform="translate(108.793 -1196.452)" clip-path="url(#clip-path-195)">
        <g id="Сгруппировать_1156" data-name="Сгруппировать 1156" transform="translate(-133.676 -168.369)" clip-path="url(#clip-path-18)">
          <path id="Контур_1461" data-name="Контур 1461" d="M703.448-562.09h39.88v47.713h-39.88Z" transform="translate(-570.534 729.696)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_1159" data-name="Сгруппировать 1159" transform="translate(105.84 -1155.203)" clip-path="url(#clip-path-197)">
        <g id="Сгруппировать_1158" data-name="Сгруппировать 1158" transform="translate(-130.723 -209.618)" clip-path="url(#clip-path-20)">
          <path id="Контур_1464" data-name="Контур 1464" d="M684.086-291.64h56.253v6.464H684.086Z" transform="translate(-554.125 500.496)" fill="#38384e"/>
        </g>
      </g>
      <g id="Сгруппировать_1161" data-name="Сгруппировать 1161" transform="translate(170.785 -1125.879)" clip-path="url(#clip-path-199)">
        <g id="Сгруппировать_1160" data-name="Сгруппировать 1160" transform="translate(-195.669 -238.942)" clip-path="url(#clip-path-2)">
          <path id="Контур_1467" data-name="Контур 1467" d="M1109.9-99.38h15.854v15.086H1109.9Z" transform="translate(-914.994 337.559)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1163" data-name="Сгруппировать 1163" transform="translate(171.462 -1117.14)" clip-path="url(#clip-path-201)">
        <g id="Сгруппировать_1162" data-name="Сгруппировать 1162" transform="translate(-196.346 -247.681)" clip-path="url(#clip-path-2)">
          <path id="Контур_1470" data-name="Контур 1470" d="M1114.339-42.08h15.177v6.346h-15.177Z" transform="translate(-918.756 288.999)" fill="#0f2448"/>
        </g>
      </g>
      <g id="Сгруппировать_1165" data-name="Сгруппировать 1165" transform="translate(123.445 -1178.415)" clip-path="url(#clip-path-203)">
        <g id="Сгруппировать_1164" data-name="Сгруппировать 1164" transform="translate(-148.328 -186.406)" clip-path="url(#clip-path-2)">
          <path id="Контур_1473" data-name="Контур 1473" d="M799.512-443.83h54.945v58.579H799.512Z" transform="translate(-651.946 629.474)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1167" data-name="Сгруппировать 1167" transform="translate(163.621 -1125.879)" clip-path="url(#clip-path-205)">
        <g id="Сгруппировать_1166" data-name="Сгруппировать 1166" transform="translate(-188.505 -238.942)" clip-path="url(#clip-path-2)">
          <path id="Контур_1476" data-name="Контур 1476" d="M1062.93-99.38h15.853v15.086H1062.93Z" transform="translate(-875.188 337.559)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1169" data-name="Сгруппировать 1169" transform="translate(164.297 -1117.14)" clip-path="url(#clip-path-207)">
        <g id="Сгруппировать_1168" data-name="Сгруппировать 1168" transform="translate(-189.18 -247.681)" clip-path="url(#clip-path-2)">
          <path id="Контур_1479" data-name="Контур 1479" d="M1067.359-42.08h15.178v6.346h-15.178Z" transform="translate(-878.941 288.999)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1171" data-name="Сгруппировать 1171" transform="translate(116.217 -1178.415)" clip-path="url(#clip-path-209)">
        <g id="Сгруппировать_1170" data-name="Сгруппировать 1170" transform="translate(-141.1 -186.406)" clip-path="url(#clip-path-2)">
          <path id="Контур_1482" data-name="Контур 1482" d="M752.123-443.83h54.945v58.579H752.123Z" transform="translate(-611.785 629.474)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1173" data-name="Сгруппировать 1173" transform="translate(125.802 -1228.484)" clip-path="url(#clip-path-211)">
        <g id="Сгруппировать_1172" data-name="Сгруппировать 1172" transform="translate(-150.685 -136.337)" clip-path="url(#clip-path-2)">
          <path id="Контур_1485" data-name="Контур 1485" d="M814.967-772.106h21.861v23.828H814.967Z" transform="translate(-665.045 907.681)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1175" data-name="Сгруппировать 1175" transform="translate(139.415 -1219.666)" clip-path="url(#clip-path-213)">
        <g id="Сгруппировать_1174" data-name="Сгруппировать 1174" transform="translate(-164.298 -145.155)" clip-path="url(#clip-path-2)">
          <path id="Контур_1488" data-name="Контур 1488" d="M904.218-714.29H912.3v9.4h-8.079Z" transform="translate(-740.682 858.682)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1177" data-name="Сгруппировать 1177" transform="translate(126.074 -1212.855)" clip-path="url(#clip-path-215)">
        <g id="Сгруппировать_1176" data-name="Сгруппировать 1176" transform="translate(-150.957 -151.966)" clip-path="url(#clip-path-2)">
          <path id="Контур_1491" data-name="Контур 1491" d="M816.75-669.638h19.9v23.49h-19.9Z" transform="translate(-666.555 820.841)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1179" data-name="Сгруппировать 1179" transform="translate(133.165 -1212.77)" clip-path="url(#clip-path-217)">
        <g id="Сгруппировать_1178" data-name="Сгруппировать 1178" transform="translate(-158.048 -152.052)" clip-path="url(#clip-path-2)">
          <path id="Контур_1494" data-name="Контур 1494" d="M863.24-669.075h8.131v7.026H863.24Z" transform="translate(-705.955 820.364)" fill="#ec975f"/>
        </g>
      </g>
      <g id="Сгруппировать_1181" data-name="Сгруппировать 1181" transform="translate(116.669 -1205.245)" clip-path="url(#clip-path-219)">
        <g id="Сгруппировать_1180" data-name="Сгруппировать 1180" transform="translate(-141.552 -159.576)" clip-path="url(#clip-path-2)">
          <path id="Контур_1497" data-name="Контур 1497" d="M755.087-619.74h58.221V-578.2H755.087Z" transform="translate(-614.297 778.554)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1183" data-name="Сгруппировать 1183" transform="translate(140.524 -1174.637)" clip-path="url(#clip-path-221)">
        <g id="Сгруппировать_1182" data-name="Сгруппировать 1182" transform="translate(-165.407 -190.184)" clip-path="url(#clip-path-2)">
          <path id="Контур_1500" data-name="Контур 1500" d="M911.49-419.06h2.819v1.53H911.49Z" transform="translate(-746.846 608.481)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1185" data-name="Сгруппировать 1185" transform="translate(132.277 -1224.272)" clip-path="url(#clip-path-223)">
        <g id="Сгруппировать_1184" data-name="Сгруппировать 1184" transform="translate(-157.16 -140.549)" clip-path="url(#clip-path-2)">
          <path id="Контур_1503" data-name="Контур 1503" d="M857.42-744.492h13.408v16.463H857.42Z" transform="translate(-701.022 884.279)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1187" data-name="Сгруппировать 1187" transform="translate(144.386 -1189.983)" clip-path="url(#clip-path-225)">
        <g id="Сгруппировать_1186" data-name="Сгруппировать 1186" transform="translate(-169.269 -174.838)" clip-path="url(#clip-path-2)">
          <path id="Контур_1506" data-name="Контур 1506" d="M936.81-519.675h22.415v14.6H936.81Z" transform="translate(-768.304 693.751)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1189" data-name="Сгруппировать 1189" transform="translate(139.146 -1212.577)" clip-path="url(#clip-path-227)">
        <g id="Сгруппировать_1188" data-name="Сгруппировать 1188" transform="translate(-164.03 -152.245)" clip-path="url(#clip-path-2)">
          <path id="Контур_1509" data-name="Контур 1509" d="M902.46-667.81h4.281v2.486H902.46Z" transform="translate(-739.193 819.292)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_1191" data-name="Сгруппировать 1191" transform="translate(133.395 -1216.646)" clip-path="url(#clip-path-229)">
        <g id="Сгруппировать_1190" data-name="Сгруппировать 1190" transform="translate(-158.278 -148.175)" clip-path="url(#clip-path-2)">
          <path id="Контур_1512" data-name="Контур 1512" d="M864.75-694.49h8.012v8.036H864.75Z" transform="translate(-707.234 841.903)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1193" data-name="Сгруппировать 1193" transform="translate(139.397 -1210.837)" clip-path="url(#clip-path-231)">
        <g id="Сгруппировать_1192" data-name="Сгруппировать 1192" transform="translate(-164.281 -153.984)" clip-path="url(#clip-path-2)">
          <path id="Контур_1515" data-name="Контур 1515" d="M904.1-656.406h2.91v2.588H904.1Z" transform="translate(-740.586 809.627)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1195" data-name="Сгруппировать 1195" transform="translate(131.257 -1225.892)" clip-path="url(#clip-path-233)">
        <g id="Сгруппировать_1194" data-name="Сгруппировать 1194" transform="translate(-156.14 -138.929)" clip-path="url(#clip-path-2)">
          <path id="Контур_1518" data-name="Контур 1518" d="M850.73-755.114h11.639v10.669H850.73Z" transform="translate(-695.353 893.28)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1197" data-name="Сгруппировать 1197" transform="translate(137.171 -1225.286)" clip-path="url(#clip-path-235)">
        <g id="Сгруппировать_1196" data-name="Сгруппировать 1196" transform="translate(-162.055 -139.536)" clip-path="url(#clip-path-2)">
          <path id="Контур_1521" data-name="Контур 1521" d="M889.51-751.136H898.9v7.51H889.51Z" transform="translate(-728.218 889.909)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1199" data-name="Сгруппировать 1199" transform="translate(135.837 -1225.864)" clip-path="url(#clip-path-237)">
        <g id="Сгруппировать_1198" data-name="Сгруппировать 1198" transform="translate(-160.72 -138.957)" clip-path="url(#clip-path-2)">
          <path id="Контур_1524" data-name="Контур 1524" d="M880.76-754.929h6.863v11.789H880.76Z" transform="translate(-720.802 893.123)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1201" data-name="Сгруппировать 1201" transform="translate(125.802 -1228.484)" clip-path="url(#clip-path-239)">
        <g id="Сгруппировать_1200" data-name="Сгруппировать 1200" transform="translate(-150.685 -136.337)" clip-path="url(#clip-path-2)">
          <path id="Контур_1527" data-name="Контур 1527" d="M814.967-772.109H829.9v20.617H814.967Z" transform="translate(-665.044 907.683)" fill="#8e342d"/>
        </g>
      </g>
      <g id="Сгруппировать_1203" data-name="Сгруппировать 1203" transform="translate(130.295 -1220.228)" clip-path="url(#clip-path-241)">
        <g id="Сгруппировать_1202" data-name="Сгруппировать 1202" transform="translate(-155.178 -144.594)" clip-path="url(#clip-path-2)">
          <path id="Контур_1530" data-name="Контур 1530" d="M844.425-717.973H852.5v9.4h-8.079Z" transform="translate(-690.009 861.804)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1205" data-name="Сгруппировать 1205" transform="translate(131.883 -1228.572)" clip-path="url(#clip-path-243)">
        <g id="Сгруппировать_1204" data-name="Сгруппировать 1204" transform="translate(-156.767 -136.249)" clip-path="url(#clip-path-2)">
          <path id="Контур_1533" data-name="Контур 1533" d="M854.84-772.685h8.314v10.751H854.84Z" transform="translate(-698.836 908.171)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1207" data-name="Сгруппировать 1207" transform="translate(131.572 -1218.201)" clip-path="url(#clip-path-245)">
        <g id="Сгруппировать_1206" data-name="Сгруппировать 1206" transform="translate(-156.455 -146.62)" clip-path="url(#clip-path-2)">
          <path id="Контур_1536" data-name="Контур 1536" d="M852.8-704.688h4.79v5.448H852.8Z" transform="translate(-697.104 850.545)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_1209" data-name="Сгруппировать 1209" transform="translate(154.874 -1180.332)" clip-path="url(#clip-path-247)">
        <g id="Сгруппировать_1208" data-name="Сгруппировать 1208" transform="translate(-179.758 -184.489)" clip-path="url(#clip-path-2)">
          <path id="Контур_1539" data-name="Контур 1539" d="M1005.58-456.4h9.738v5.927h-9.738Z" transform="translate(-826.585 640.128)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1211" data-name="Сгруппировать 1211" transform="translate(128.526 -1206.293)" clip-path="url(#clip-path-249)">
        <g id="Сгруппировать_1210" data-name="Сгруппировать 1210" transform="translate(-153.41 -158.528)" clip-path="url(#clip-path-2)">
          <path id="Контур_1542" data-name="Контур 1542" d="M832.83-626.61H845.6V-610.5H832.83Z" transform="translate(-680.183 784.376)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1213" data-name="Сгруппировать 1213" transform="translate(123.86 -1193.243)" clip-path="url(#clip-path-251)">
        <g id="Сгруппировать_1212" data-name="Сгруппировать 1212" transform="translate(-148.744 -171.578)" clip-path="url(#clip-path-2)">
          <path id="Контур_1545" data-name="Контур 1545" d="M802.236-541.05h22.113v23.172H802.236Z" transform="translate(-654.255 711.865)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1215" data-name="Сгруппировать 1215" transform="translate(139.503 -1206.293)" clip-path="url(#clip-path-253)">
        <g id="Сгруппировать_1214" data-name="Сгруппировать 1214" transform="translate(-164.387 -158.528)" clip-path="url(#clip-path-2)">
          <path id="Контур_1548" data-name="Контур 1548" d="M904.8-626.61h4.307V-610.5H904.8Z" transform="translate(-741.176 784.376)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1217" data-name="Сгруппировать 1217" transform="translate(163.293 -1180.926)" clip-path="url(#clip-path-255)">
        <g id="Сгруппировать_1216" data-name="Сгруппировать 1216" transform="translate(-188.176 -183.896)" clip-path="url(#clip-path-2)">
          <path id="Контур_1551" data-name="Контур 1551" d="M1060.776-460.29h11.6v5.546h-11.6Z" transform="translate(-873.362 643.423)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1219" data-name="Сгруппировать 1219" transform="translate(155.867 -1176.063)" clip-path="url(#clip-path-257)">
        <g id="Сгруппировать_1218" data-name="Сгруппировать 1218" transform="translate(-180.751 -188.758)" clip-path="url(#clip-path-258)">
          <path id="Контур_1554" data-name="Контур 1554" d="M1012.09-428.41h19.483v2.956H1012.09Z" transform="translate(-832.102 616.405)" fill="#323248"/>
        </g>
      </g>
      <g id="Сгруппировать_1221" data-name="Сгруппировать 1221" transform="translate(166.934 -1194.19)" clip-path="url(#clip-path-259)">
        <g id="Сгруппировать_1220" data-name="Сгруппировать 1220" transform="translate(-191.818 -170.631)" clip-path="url(#clip-path-260)">
          <path id="Контур_1557" data-name="Контур 1557" d="M1084.65-547.26h36.129v21.083H1084.65Z" transform="translate(-893.595 717.128)" fill="#44445d"/>
        </g>
      </g>
      <g id="Сгруппировать_1223" data-name="Сгруппировать 1223" transform="translate(167.343 -1194.19)" clip-path="url(#clip-path-261)">
        <g id="Сгруппировать_1222" data-name="Сгруппировать 1222" transform="translate(-192.226 -170.631)" clip-path="url(#clip-path-18)">
          <path id="Контур_1560" data-name="Контур 1560" d="M1087.33-547.26h35.72v21.083h-35.72Z" transform="translate(-895.866 717.128)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_1225" data-name="Сгруппировать 1225" transform="translate(184.236 -1185.945)" clip-path="url(#clip-path-263)">
        <g id="Сгруппировать_1224" data-name="Сгруппировать 1224" transform="translate(-209.12 -178.876)" clip-path="url(#clip-path-264)">
          <path id="Контур_1563" data-name="Контур 1563" d="M1198.092-493.2h4.3v4.231h-4.3Z" transform="translate(-989.735 671.314)" fill="#2d2d40"/>
        </g>
      </g>
      <g id="Сгруппировать_1227" data-name="Сгруппировать 1227" transform="translate(182.288 -1194.19)" clip-path="url(#clip-path-265)">
        <g id="Сгруппировать_1226" data-name="Сгруппировать 1226" transform="translate(-207.172 -170.631)" clip-path="url(#clip-path-264)">
          <path id="Контур_1566" data-name="Контур 1566" d="M1185.32-547.26h20.775v20.9H1185.32Z" transform="translate(-978.911 717.128)" fill="#2d2d40"/>
        </g>
      </g>
      <g id="Сгруппировать_1229" data-name="Сгруппировать 1229" transform="translate(140.524 -1183.151)" clip-path="url(#clip-path-267)">
        <g id="Сгруппировать_1228" data-name="Сгруппировать 1228" transform="translate(-165.407 -181.67)" clip-path="url(#clip-path-2)">
          <path id="Контур_1569" data-name="Контур 1569" d="M911.49-474.88h15.879v6.587H911.49Z" transform="translate(-746.846 655.788)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1231" data-name="Сгруппировать 1231" transform="translate(121.143 -1204.979)" clip-path="url(#clip-path-269)">
        <g id="Сгруппировать_1230" data-name="Сгруппировать 1230" transform="translate(-146.027 -159.842)" clip-path="url(#clip-path-2)">
          <path id="Контур_1572" data-name="Контур 1572" d="M784.422-618h22.4v28.4h-22.4Z" transform="translate(-639.158 777.079)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1233" data-name="Сгруппировать 1233" transform="translate(96.615 -1172.586)" clip-path="url(#clip-path-271)">
        <g id="Сгруппировать_1232" data-name="Сгруппировать 1232" transform="translate(-121.498 -192.235)" clip-path="url(#clip-path-2)">
          <path id="Контур_1575" data-name="Контур 1575" d="M623.6-405.61h4.542v62.472H623.6Z" transform="translate(-502.865 597.083)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1235" data-name="Сгруппировать 1235" transform="translate(91.407 -1174.611)" clip-path="url(#clip-path-273)">
        <g id="Сгруппировать_1234" data-name="Сгруппировать 1234" transform="translate(-116.29 -190.21)" clip-path="url(#clip-path-2)">
          <path id="Контур_1578" data-name="Контур 1578" d="M589.454-418.89h135.97v5.119H589.454Z" transform="translate(-473.927 608.337)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1237" data-name="Сгруппировать 1237" transform="translate(384.294 -1172.586)" clip-path="url(#clip-path-275)">
        <g id="Сгруппировать_1236" data-name="Сгруппировать 1236" transform="translate(-409.178 -192.235)" clip-path="url(#clip-path-2)">
          <path id="Контур_1581" data-name="Контур 1581" d="M2509.77-405.61h4.542v62.472h-4.542Z" transform="translate(-2101.355 597.083)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1239" data-name="Сгруппировать 1239" transform="translate(212.82 -1174.611)" clip-path="url(#clip-path-277)">
        <g id="Сгруппировать_1238" data-name="Сгруппировать 1238" transform="translate(-237.703 -190.21)" clip-path="url(#clip-path-2)">
          <path id="Контур_1584" data-name="Контур 1584" d="M1385.5-418.89h180.33v5.119H1385.5Z" transform="translate(-1148.559 608.337)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_1241" data-name="Сгруппировать 1241" transform="translate(244.358 -1176.063)" clip-path="url(#clip-path-279)">
        <g id="Сгруппировать_1240" data-name="Сгруппировать 1240" transform="translate(-269.242 -188.758)" clip-path="url(#clip-path-258)">
          <path id="Контур_1587" data-name="Контур 1587" d="M1592.28-428.41h19.484v2.956H1592.28Z" transform="translate(-1323.801 616.405)" fill="#323248"/>
        </g>
      </g>
      <g id="Сгруппировать_1243" data-name="Сгруппировать 1243" transform="translate(255.425 -1194.19)" clip-path="url(#clip-path-281)">
        <g id="Сгруппировать_1242" data-name="Сгруппировать 1242" transform="translate(-280.309 -170.631)" clip-path="url(#clip-path-264)">
          <path id="Контур_1590" data-name="Контур 1590" d="M1664.84-547.26h36.129v21.083H1664.84Z" transform="translate(-1385.294 717.128)" fill="#2d2d40"/>
        </g>
      </g>
      <g id="Сгруппировать_1245" data-name="Сгруппировать 1245" transform="translate(255.834 -1194.19)" clip-path="url(#clip-path-283)">
        <g id="Сгруппировать_1244" data-name="Сгруппировать 1244" transform="translate(-280.717 -170.631)" clip-path="url(#clip-path-18)">
          <path id="Контур_1593" data-name="Контур 1593" d="M1667.52-547.26h35.72v21.083h-35.72Z" transform="translate(-1387.565 717.128)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_1247" data-name="Сгруппировать 1247" transform="translate(272.728 -1185.945)" clip-path="url(#clip-path-285)">
        <g id="Сгруппировать_1246" data-name="Сгруппировать 1246" transform="translate(-297.611 -178.876)" clip-path="url(#clip-path-264)">
          <path id="Контур_1596" data-name="Контур 1596" d="M1778.285-493.2h4.3v4.231h-4.3Z" transform="translate(-1481.436 671.314)" fill="#2d2d40"/>
        </g>
      </g>
      <g id="Сгруппировать_1249" data-name="Сгруппировать 1249" transform="translate(270.779 -1194.19)" clip-path="url(#clip-path-287)">
        <g id="Сгруппировать_1248" data-name="Сгруппировать 1248" transform="translate(-295.663 -170.631)" clip-path="url(#clip-path-264)">
          <path id="Контур_1599" data-name="Контур 1599" d="M1765.51-547.26h20.775v20.9H1765.51Z" transform="translate(-1470.61 717.128)" fill="#2d2d40"/>
        </g>
      </g>
      <g id="Сгруппировать_1251" data-name="Сгруппировать 1251" transform="translate(295.087 -1194.19)" clip-path="url(#clip-path-289)">
        <g id="Сгруппировать_1250" data-name="Сгруппировать 1250" transform="translate(-319.97 -170.631)" clip-path="url(#clip-path-290)">
          <path id="Контур_1602" data-name="Контур 1602" d="M1924.88-547.26h36.131v21.1H1924.88Z" transform="translate(-1605.673 717.128)" fill="#b5b5c3"/>
        </g>
      </g>
      <g id="Сгруппировать_1253" data-name="Сгруппировать 1253" transform="translate(295.883 -1193.762)" clip-path="url(#clip-path-291)">
        <g id="Сгруппировать_1252" data-name="Сгруппировать 1252" transform="translate(-320.766 -171.06)" clip-path="url(#clip-path-2)">
          <path id="Контур_1605" data-name="Контур 1605" d="M1930.1-544.45h34.926V-523.8H1930.1Z" transform="translate(-1610.096 714.747)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_1255" data-name="Сгруппировать 1255" transform="translate(301.39 -1176.063)" clip-path="url(#clip-path-293)">
        <g id="Сгруппировать_1254" data-name="Сгруппировать 1254" transform="translate(-326.274 -188.758)" clip-path="url(#clip-path-2)">
          <path id="Контур_1608" data-name="Контур 1608" d="M1966.21-428.41H2007.1v2.977H1966.21Z" transform="translate(-1640.699 616.405)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1257" data-name="Сгруппировать 1257" transform="translate(349.28 -1215.694)" clip-path="url(#clip-path-295)">
        <g id="Сгруппировать_1256" data-name="Сгруппировать 1256" transform="translate(-374.164 -149.127)" clip-path="url(#clip-path-2)">
          <path id="Контур_1611" data-name="Контур 1611" d="M2280.2-688.248h7.818v9.366H2280.2Z" transform="translate(-1906.799 836.613)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1259" data-name="Сгруппировать 1259" transform="translate(321.912 -1120.525)" clip-path="url(#clip-path-297)">
        <g id="Сгруппировать_1258" data-name="Сгруппировать 1258" transform="translate(-346.795 -244.296)" clip-path="url(#clip-path-2)">
          <path id="Контур_1614" data-name="Контур 1614" d="M2100.76-64.277h16.59v9.722h-16.59Z" transform="translate(-1754.727 307.81)" fill="#0e2c4f"/>
        </g>
      </g>
      <g id="Сгруппировать_1261" data-name="Сгруппировать 1261" transform="translate(329.895 -1168.94)" clip-path="url(#clip-path-299)">
        <g id="Сгруппировать_1260" data-name="Сгруппировать 1260" transform="translate(-354.778 -195.881)" clip-path="url(#clip-path-2)">
          <path id="Контур_1617" data-name="Контур 1617" d="M2153.1-381.71h37.313v52.6H2153.1Z" transform="translate(-1799.084 576.828)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_1263" data-name="Сгруппировать 1263" transform="translate(296.025 -1178.243)" clip-path="url(#clip-path-301)">
        <g id="Сгруппировать_1262" data-name="Сгруппировать 1262" transform="translate(-320.908 -186.578)" clip-path="url(#clip-path-2)">
          <path id="Контур_1620" data-name="Контур 1620" d="M1931.03-442.7h10.1v5.147h-10.1Z" transform="translate(-1610.885 628.52)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1265" data-name="Сгруппировать 1265" transform="translate(308.812 -1120.525)" clip-path="url(#clip-path-303)">
        <g id="Сгруппировать_1264" data-name="Сгруппировать 1264" transform="translate(-333.695 -244.296)" clip-path="url(#clip-path-2)">
          <path id="Контур_1623" data-name="Контур 1623" d="M2014.87-64.277h16.591v9.722H2014.87Z" transform="translate(-1681.937 307.81)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_1267" data-name="Сгруппировать 1267" transform="translate(317.169 -1168.94)" clip-path="url(#clip-path-305)">
        <g id="Сгруппировать_1266" data-name="Сгруппировать 1266" transform="translate(-342.052 -195.881)" clip-path="url(#clip-path-2)">
          <path id="Контур_1626" data-name="Контур 1626" d="M2069.66-381.71h52.886v52.352H2069.66Z" transform="translate(-1728.371 576.828)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1269" data-name="Сгруппировать 1269" transform="translate(344.426 -1208.637)" clip-path="url(#clip-path-307)">
        <g id="Сгруппировать_1268" data-name="Сгруппировать 1268" transform="translate(-369.309 -156.184)" clip-path="url(#clip-path-2)">
          <path id="Контур_1629" data-name="Контур 1629" d="M2248.373-641.98h10.546v13.073h-10.546Z" transform="translate(-1879.826 797.402)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1271" data-name="Сгруппировать 1271" transform="translate(342.948 -1203.453)" clip-path="url(#clip-path-309)">
        <g id="Сгруппировать_1270" data-name="Сгруппировать 1270" transform="translate(-367.831 -161.368)" clip-path="url(#clip-path-2)">
          <path id="Контур_1632" data-name="Контур 1632" d="M2238.68-607.994h12.354v6.423H2238.68Z" transform="translate(-1871.612 768.599)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_1273" data-name="Сгруппировать 1273" transform="translate(304.596 -1201.005)" clip-path="url(#clip-path-311)">
        <g id="Сгруппировать_1272" data-name="Сгруппировать 1272" transform="translate(-329.48 -163.816)" clip-path="url(#clip-path-2)">
          <path id="Контур_1635" data-name="Контур 1635" d="M1987.23-591.94h68.8v51.689h-68.8Z" transform="translate(-1658.513 754.994)" fill="#d2e2fb"/>
        </g>
      </g>
      <g id="Сгруппировать_1275" data-name="Сгруппировать 1275" transform="translate(344.477 -1205.544)" clip-path="url(#clip-path-313)">
        <g id="Сгруппировать_1274" data-name="Сгруппировать 1274" transform="translate(-369.361 -159.277)" clip-path="url(#clip-path-2)">
          <path id="Контур_1638" data-name="Контур 1638" d="M2248.71-621.7h7.1v4.5h-7.1Z" transform="translate(-1880.112 780.215)" fill="#eaa06d"/>
        </g>
      </g>
      <g id="Сгруппировать_1277" data-name="Сгруппировать 1277" transform="translate(340.482 -1218.696)" clip-path="url(#clip-path-315)">
        <g id="Сгруппировать_1276" data-name="Сгруппировать 1276" transform="translate(-365.365 -146.125)" clip-path="url(#clip-path-2)">
          <path id="Контур_1641" data-name="Контур 1641" d="M2222.513-707.934h13.47V-691.8h-13.47Z" transform="translate(-1857.91 853.296)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_1279" data-name="Сгруппировать 1279" transform="translate(339.86 -1221.71)" clip-path="url(#clip-path-317)">
        <g id="Сгруппировать_1278" data-name="Сгруппировать 1278" transform="translate(-364.744 -143.111)" clip-path="url(#clip-path-2)">
          <path id="Контур_1644" data-name="Контур 1644" d="M2218.438-727.7h17.388v17.1h-17.388Z" transform="translate(-1854.457 870.043)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_1281" data-name="Сгруппировать 1281" transform="translate(334.867 -1154.593)" clip-path="url(#clip-path-319)">
        <g id="Сгруппировать_1280" data-name="Сгруппировать 1280" transform="translate(-359.75 -210.228)" clip-path="url(#clip-path-2)">
          <path id="Контур_1647" data-name="Контур 1647" d="M2185.7-287.64h47.759v6.435H2185.7Z" transform="translate(-1826.712 497.106)" fill="#77a3e4"/>
        </g>
      </g>
      <g id="Сгруппировать_1283" data-name="Сгруппировать 1283" transform="translate(327.484 -1154.593)" clip-path="url(#clip-path-321)">
        <g id="Сгруппировать_1282" data-name="Сгруппировать 1282" transform="translate(-352.367 -210.228)" clip-path="url(#clip-path-20)">
          <path id="Контур_1650" data-name="Контур 1650" d="M2137.29-287.64h38.652v6.435H2137.29Z" transform="translate(-1785.686 497.106)" fill="#38384e"/>
        </g>
      </g>
      <g id="Сгруппировать_1285" data-name="Сгруппировать 1285" transform="translate(356.24 -1149.683)" clip-path="url(#clip-path-323)">
        <g id="Сгруппировать_1284" data-name="Сгруппировать 1284" transform="translate(-381.123 -215.138)" clip-path="url(#clip-path-2)">
          <path id="Контур_1653" data-name="Контур 1653" d="M2325.83-255.45h5.788v37.032h-5.788Z" transform="translate(-1945.469 469.825)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1287" data-name="Сгруппировать 1287" transform="translate(344.802 -1117.875)" clip-path="url(#clip-path-325)">
        <g id="Сгруппировать_1286" data-name="Сгруппировать 1286" transform="translate(-369.686 -246.946)" clip-path="url(#clip-path-2)">
          <path id="Контур_1656" data-name="Контур 1656" d="M2250.84-46.9H2279.5v5.224H2250.84Z" transform="translate(-1881.917 293.084)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1289" data-name="Сгруппировать 1289" transform="translate(370.112 -1116.794)" clip-path="url(#clip-path-327)">
        <g id="Сгруппировать_1288" data-name="Сгруппировать 1288" transform="translate(-394.995 -248.028)" clip-path="url(#clip-path-2)">
          <path id="Контур_1659" data-name="Контур 1659" d="M2416.78-39.81h5.99v5.989h-5.99Z" transform="translate(-2022.548 287.075)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1291" data-name="Сгруппировать 1291" transform="translate(342.974 -1116.794)" clip-path="url(#clip-path-329)">
        <g id="Сгруппировать_1290" data-name="Сгруппировать 1290" transform="translate(-367.857 -248.028)" clip-path="url(#clip-path-2)">
          <path id="Контур_1662" data-name="Контур 1662" d="M2238.85-39.81h5.991v5.989h-5.991Z" transform="translate(-1871.756 287.075)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1293" data-name="Сгруппировать 1293" transform="translate(343.445 -1149.683)" clip-path="url(#clip-path-331)">
        <g id="Сгруппировать_1292" data-name="Сгруппировать 1292" transform="translate(-368.328 -215.138)" clip-path="url(#clip-path-2)">
          <path id="Контур_1665" data-name="Контур 1665" d="M2241.94-255.45h31.376v3.854H2241.94Z" transform="translate(-1874.374 469.825)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_1295" data-name="Сгруппировать 1295" transform="translate(340.825 -1195.598)" clip-path="url(#clip-path-333)">
        <g id="Сгруппировать_1294" data-name="Сгруппировать 1294" transform="translate(-365.708 -169.223)" clip-path="url(#clip-path-334)">
          <path id="Контур_1668" data-name="Контур 1668" d="M2224.76-556.49h39.652v47.44H2224.76Z" transform="translate(-1859.815 724.951)" fill="#464660"/>
        </g>
      </g>
      <g id="Сгруппировать_1297" data-name="Сгруппировать 1297" transform="translate(342.974 -1195.598)" clip-path="url(#clip-path-335)">
        <g id="Сгруппировать_1296" data-name="Сгруппировать 1296" transform="translate(-367.857 -169.223)" clip-path="url(#clip-path-18)">
          <path id="Контур_1671" data-name="Контур 1671" d="M2238.85-556.49H2278.5v47.44H2238.85Z" transform="translate(-1871.756 724.951)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_1299" data-name="Сгруппировать 1299" transform="translate(339.139 -1210.527)" clip-path="url(#clip-path-337)">
        <g id="Сгруппировать_1298" data-name="Сгруппировать 1298" transform="translate(-364.023 -154.294)" clip-path="url(#clip-path-2)">
          <path id="Контур_1674" data-name="Контур 1674" d="M2213.71-654.37h8.012v8.036h-8.012Z" transform="translate(-1850.45 807.902)" fill="#122444"/>
        </g>
      </g>
      <g id="Сгруппировать_1301" data-name="Сгруппировать 1301" transform="translate(338.239 -1204.719)" clip-path="url(#clip-path-339)">
        <g id="Сгруппировать_1300" data-name="Сгруппировать 1300" transform="translate(-363.123 -160.102)" clip-path="url(#clip-path-2)">
          <path id="Контур_1677" data-name="Контур 1677" d="M2207.81-616.29h2.909v2.588h-2.909Z" transform="translate(-1845.45 775.63)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1303" data-name="Сгруппировать 1303" transform="translate(341.705 -1213.688)" clip-path="url(#clip-path-341)">
        <g id="Сгруппировать_1302" data-name="Сгруппировать 1302" transform="translate(-366.589 -151.134)" clip-path="url(#clip-path-2)">
          <path id="Контур_1680" data-name="Контур 1680" d="M2230.535-675.094h8.079v9.4h-8.079Z" transform="translate(-1864.709 825.465)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1305" data-name="Сгруппировать 1305" transform="translate(343.294 -1222.032)" clip-path="url(#clip-path-343)">
        <g id="Сгруппировать_1304" data-name="Сгруппировать 1304" transform="translate(-368.177 -142.789)" clip-path="url(#clip-path-2)">
          <path id="Контур_1683" data-name="Контур 1683" d="M2240.95-729.805h8.314v10.751h-8.314Z" transform="translate(-1873.535 871.832)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_1307" data-name="Сгруппировать 1307" transform="translate(342.982 -1211.662)" clip-path="url(#clip-path-345)">
        <g id="Сгруппировать_1306" data-name="Сгруппировать 1306" transform="translate(-367.865 -153.159)" clip-path="url(#clip-path-2)">
          <path id="Контур_1686" data-name="Контур 1686" d="M2238.906-661.812h4.79v5.449h-4.79Z" transform="translate(-1871.803 814.208)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_1309" data-name="Сгруппировать 1309" transform="translate(7.112 -1112.761)" clip-path="url(#clip-path-347)">
        <g id="Сгруппировать_1308" data-name="Сгруппировать 1308" transform="translate(-31.996 -252.06)" clip-path="url(#clip-path-348)">
          <path id="Контур_1689" data-name="Контур 1689" d="M36.78-13.37H478.693v2.64H36.78Z" transform="translate(-5.547 264.668)" fill="#cddff0"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "GroupImage"
}
</script>

<style scoped>

</style>
